import { useEffect, useState } from "react";
import delete_icon from './img/trash_icon.svg'
import edit_icon from './img/edit_icon.svg'
import { AuthorAvatar } from "../../ArticleComponents/AuthorAvatar/AuthorAvatar";
import './ChatPreview.scss'

const ChatPreview = (props) => {
    const [data, setData] = useState(props)

    const {
        chat_id,
        name,
        members,
        currentMessage,
        setCurrentMessage,
        unread_messages_count,
        setIsModalCreateGroup,
        setIsEditGroup,
        type
    } = data

    useEffect(() => {
        setData(props)
    }, [props])

    return (
        <div
            className={currentMessage?.chat?.chat_id == data?.chat?.chat_id ? 'chat-preview_active' : 'chat-preview'}
            key={name + chat_id}
            onClick={(e) => setCurrentMessage(props)}
        >
            <div className='chat-preview__content'>
                <div className='chat-preview__content-title-members'>
                    {name && <h3 className='chat-preview__title'>{name}</h3>}
                    <div className='employees-container'>
                        {members?.map(({ name, login }) =>
                            <AuthorAvatar
                                size='xs'
                                group
                                show_name={type == 'chat'}
                                key={name + login}
                                author={[name]}
                            />
                        )?.splice(0, 12)}

                        {(members?.length > 12) && <p className='sum_executor'>+<span>{members?.length - 12}</span></p>}
                    </div>
                </div>

                <div className='btn-group'>
                    <button className='btn-edit'
                        onClick={(e) => {
                            e.stopPropagation()
                            setCurrentMessage(props)
                            setIsEditGroup(true)
                            setIsModalCreateGroup(true)
                        }}
                    >
                        <img style={{ width: 18, height: 18 }} src={edit_icon} />
                    </button>
                    <button className='btn-delete'
                        onClick={e => {
                            e.stopPropagation()
                            // deleteGroup(id)
                        }}
                    >
                        <img src={delete_icon} />
                    </button>
                </div>
            </div>
        </div>
    )
}


export { ChatPreview };
