import './AuthorAvatar.scss'

function AuthorAvatar({
    show_name,
    author,
    size = 's',
    group = false
}) {

    const current_className = size === 's' ? 'author-avatar' : size === 'xs' ? 'author-avatar-xs' : ''
    const position_classname = group ? 'author-avatar_left' : ''

    return (
        <div className='author-avatar__wrapper'>
            <div className={current_className + ' ' + position_classname}
                style={{
                    background: author?.[1] ? author?.[1] : ''
                }}
            >{author?.[0]?.split(' ')
                .map(str => str[0])
                .reduce((acc, current) => acc += current, '')?.toUpperCase() || '-'}
            </div>
            {(show_name && author?.[0]) && <span className='author-avatar__name'>{author?.[0]}</span>}
        </div>
    );
}

export { AuthorAvatar };


