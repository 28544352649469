import { useState, useEffect, forwardRef } from 'react'
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { ModalBitrixForm } from './ModalBitrixForm/ModalBitrixForm'
import { EditWrapper } from '../EditWrapper/EditWrapper';
import './FormBtn.scss'

const formStubTitle = ''
const formStubSubTitle = ''
const formStubBitrixToken = 'hxdxe0cpclw65c0j'

const FormBtn = forwardRef(({
  data,
  editMode,
  onChange,
  changeContent,
  position,
  setContentArr,
  id,
  setCurrentIndex,
  ind,
  setIsModal,
  before,
  setBeforeBlock,
  deleteBlock,
  readonly = false
}, ref) => {
  const [isModalBitrix, setIsModalBitrix] = useState(false)
  const activeLang = localStorage.getItem('lang')
  const activePortal = localStorage.getItem('portal_id')

  const formStubData = [
    { id: 'FIELDS[TITLE]', placeholder: 'Название лида', hidden: true, value: 'Лид клиент', inputType: 'text', type: 'input' },
    { id: 'FIELDS[NAME]', placeholder: 'Имя', inputType: 'text', type: 'input', required: true },
    { id: 'FIELDS[EMAIL][0][VALUE]', placeholder: 'Email', type: 'input', inputType: 'email' },
    { id: 'FIELDS[PHONE][0][VALUE]', placeholder: 'Номер телефона', type: 'input', inputType: 'tel' },
    { id: 'FIELDS[COMMENTS]', placeholder: 'Комментарии', type: 'textarea', inputType: 'text' },

    { id: 'FIELDS[SOURCE_ID]', placeholder: 'выбирается из справочника источников в CRM', type: 'input', hidden: true, value: window.location.href?.indexOf('ecomru') > -1 ? 4 : 13 },
    { id: 'FIELDS[SOURCE_DESCRIPTION]', placeholder: 'URL страницы', type: 'input', value: window.location.href, hidden: true, },
    { id: 'FIELDS[ASSIGNED_BY_ID]', placeholder: 'ФИО ответственного', type: 'input', value: 11, hidden: true, },
    { id: 'FIELDS[UTM_CAMPAIGN]', placeholder: 'Обозначение рекламной кампании', type: 'input', value: '', hidden: true, },
    { id: 'FIELDS[UTM_CONTENT]', placeholder: 'Содержание кампании', type: 'input', value: '', hidden: true, },
    { id: 'FIELDS[UTM_MEDIUM]', placeholder: 'Тип трафика', value: '', type: 'input', hidden: true, },
    { id: 'FIELDS[UTM_SOURCE]', placeholder: 'Рекламная система', value: '', type: 'input', hidden: true, },
    { id: 'FIELDS[UTM_TERM]', placeholder: 'Условие поиска кампании', value: '', type: 'input', hidden: true, }
  ]


  const currentColor = () => {
    if (activePortal == '6') return '#22C55E' //gtcom зел
    if (activePortal == '2') return '#1890FF' //ecom гол
    if (activePortal == '1') return '#3900B1' //ecom фиол
    return '#3900B1'
  }

  const translatePlaceholderOpen = () => {
    if (activeLang == 'ru') return 'Получить консультацию'
    if (activeLang == 'en') return 'Leave a request'
    if (activeLang == 'tr') return "Bir istek bırakın"
    return 'Получить консультацию'
  }

  const translateTitle = () => {
    if (activeLang == 'en') return 'Free consultation'
    if (activeLang == 'tr') return 'Uzmanlarımızdan ücretsiz'
    return 'Бесплатная консультация'
  }

  const translateDescription = () => {
    if (activeLang == 'en') return 'from our expert'
    if (activeLang == 'tr') return 'danışmanlık'
    return 'от нашего специалиста'
  }
  // Удалить блок
  const handleDeleteBlock = (e) => {
    //  setContent(e.currentTarget.textContent)
    setContentArr((prev) => {
      return prev.filter((el) => {
        if (el?.block_id != id) {
          return el
        }
      })
    })
    deleteBlock(id)
  }

  useEffect(() => {
    setCurrentIndex(ind)
    setBeforeBlock(before)
  }, []);

  return (
    <EditWrapper
      readonly={readonly}
      deleteFunc={(e) => handleDeleteBlock(id)}
      type='form'

      child={<div className='form-consultation'
        tabIndex={0}
        onClick={(e) => {
          console.log(before)
          setCurrentIndex(ind)
          setBeforeBlock(before)
        }}
        onContextMenu={(e) => {
          e.preventDefault()
          setCurrentIndex(ind)
          setIsModal(true)
        }}
      >

        {isModalBitrix && <ModalBitrixForm
          formTitle='Заявка на консультацию'
          setIsModal={(e) => setIsModalBitrix(e)}
          inputArr={formStubData}
          bitrixToken={formStubBitrixToken}
          subTitle={'Оставьте заявку и получите бесплатную консультацию'}
        />}

        {/* {isOpen && <div className='blackout' >
        <div className='modal-feedback-2'>
          <script data-b24-form="inline/87/cqh2lf" data-skip-moving="true">
            {
              (function (w, d, u) {
                var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 180000 | 0);
                var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
              })(window, document, 'https://cdn-ru.bitrix24.ru/b17903668/crm/form/loader_87.js')
            }
          </script>
        </div>
      </div>} */}

        <p className='text_blue'
          onClick={(e) => {
            setCurrentIndex(ind)
            setBeforeBlock(before)
          }}
          style={{ color: currentColor() }}>{translateTitle()}</p>
        <p className='text_grey'>{translateDescription()}</p>
        <ButtonBasic
          green
          maxWidth='560px'
          width='100%'
          size='60px'
          onClick={(e) => setIsModalBitrix(true)}
          text={translatePlaceholderOpen()}
          style={{ marginTop: '14px' }}
        />
      </div>}
    />

  )
})

export { FormBtn }