import { MoonLoader } from 'react-spinners'
import { RavshanHttps } from '../../../fetchUrls'
import { CardExecutor } from '../../CardExecutor/CardExecutor'
import { useState, useEffect } from 'react'
import { InputDinamycPlaceholder, ButtonBasic, Tabs } from "@lk-gtcom/ecomlab-components"
import './ModalCreateGroup.scss'

const btn_tab = [
    {
        label: 'Добавить',
        value: 'add'
    },
    {
        label: 'Добавленные',
        value: 'added'
    }
]

const ModalCreateGroup = ({
    status,
    setIsModal,
    createGroup,
    createChat,
    type,
    usersList,
    currentMessage,
    isEdit,
    setIsEdit,
    editGroup,
    fetchAddUsers,
    fetchDeleteUsers
}) => {
    const [load, setLoad] = useState(false)
    const [nameGroup, setGroupName] = useState('')
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const id_members = currentMessage?.members?.map((el) => el.id)
    const [selectedUsers, setSelectedUsers] = useState(isEdit ? id_members : [])

    console.log(status)

    useEffect(() => {
        if (isEdit) {
            setGroupName(currentMessage?.name)
        }
    }, [isEdit]);

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            e.stopPropagation()
            if (el.classList.contains('blackout')) {
                setIsEdit(false)
                setIsModal(false)
            }
        }}>
            <div className='modal-сreate-group'>
                <div className='modal-сreate-group__header'>
                    <h1 className='title'>{type ?
                        (isEdit ? 'Редактирование чата' : 'Создание чата')
                        :
                        (isEdit ? 'Редактирование группы' : 'Создание группы')
                    }</h1>
                </div>
                <div className='toolbar-medium'>
                    <InputDinamycPlaceholder
                        value={nameGroup}
                        changeValue={isEdit ? currentMessage?.name : ''}
                        onChange={(e) => setGroupName(e)}
                        placeholder={type ? 'Название чата' : 'Название группы'}
                        size='s'
                    />
                </div>

                {/* <Tabs tabs={btn_tab} stopRedirect pageValue={pageValue} setPageValue={(e) => setPageValue(e)} /> */}

                {load ?
                    <div className='center' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MoonLoader
                            size={40}
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                        />
                    </div>
                    :
                    <div className='modal-сreate-group__content'>
                        {usersList?.map((props, i) => {
                            const id = props?.id
                            return <CardExecutor
                                key={id}
                                {...props}
                                color={'#F9B088'}
                                status={selectedUsers?.indexOf(id) >= 0}
                                addNewMember={(e) => {
                                    // if (type) {
                                    //     createChat(id)
                                    //     setIsModal(false)
                                    //     e.stopPropagation()
                                    // } else {
                                    if (selectedUsers?.indexOf(id) !== -1) {
                                        setSelectedUsers(prev => prev?.filter(el => el !== id))
                                        if (isEdit) {
                                            fetchDeleteUsers(currentMessage?.chat?.chat_id, id)
                                        }
                                    } else {
                                        setSelectedUsers(prev => [...prev, id])
                                        // добавляем участника приредактировании
                                        if (isEdit) {
                                            fetchAddUsers(currentMessage?.chat?.chat_id, id)
                                        }
                                    }

                                    // }
                                }}
                            />
                        })}
                    </div>
                }
                {

                    <div className='modal-сreate-group__group-btn'>
                        <ButtonBasic
                            green
                            width='260px'
                            text='Сохранить'
                            size='56px'
                            onClick={e => {
                                if (isEdit) {
                                    editGroup(currentMessage?.chat?.chat_id, nameGroup)
                                } else {
                                    if (status == 'все') {
                                        createGroup(selectedUsers, nameGroup)
                                    } else {
                                        createChat(nameGroup)
                                    }
                                }
                                setIsEdit(false)
                                setIsModal(false)
                            }}
                        />
                    </div>
                }
            </div>
        </div >
    )
}

export { ModalCreateGroup }