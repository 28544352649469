// import { ShareComponent } from '../../ShareComponent/ShareComponent'
import { useState } from 'react'
import { useEffect } from 'react'
import parse from 'html-react-parser'
import './ListOfContent.scss'

const ListOfContent = ({
    links,
    lang,
    articlePath
}) => {


    const [activeSection, setActiveSection] = useState('');
    const [init, setInit] = useState(true)

    const handleScroll = () => {
        const sections = document.querySelectorAll('.article__title');

        sections.forEach(section => {
            const rect = section.getBoundingClientRect();
            if (rect.top < 152) {
                setActiveSection(section.id);
            }
        });
    };

    const handleHashChange = () => {
        // const hash = window.location.hash.replace('#', '');  // Получаем часть хеша после '#'
        // setActiveSection(hash);

        // // Прокручиваем страницу к нужному элементу
        // const targetElement = document.getElementById(hash);
        // if (targetElement) {
        //     targetElement.scrollIntoView({ behavior: 'smooth' });
        // }
    };

    useEffect(() => {
        window.addEventListener('hashchange', handleHashChange);
        handleHashChange();
        setInit(false)

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [links]);

    useEffect(() => {
        if (activeSection && !init) {
            window?.history.pushState(null, '', `#${activeSection}`);

        }
    }, [activeSection]);

    useEffect(() => {
        if (!init) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    }, [init]);


    const linksList = links?.map(({ title, id }, index) => <a
        key={index}
        href={`#${id}`}
        className='list-of-content__link'
    //onClick={() => window?.history.replaceState(null, '', `#${path}`)}
    >
        <span style={{
            background: activeSection == id ? '#243AFF' : ''
        }}
            className='list-of-content__point'></span>
        <span className='list-of-content__label'
            style={{
                color: activeSection == id ? '#243AFF !important' : ''
            }}
        >{parse(title)}</span>
    </a>)

    const translateContent = () => {
        if (lang == 'en') return 'Table of Contents'
        if (lang == 'tr') return 'Içerik'
        return 'Содержание'
    }

    return (
        <section className='list-of-content'>
            <div className='list-of-content__content'>
                <h4 className='list-of-content__title'>{translateContent()}</h4>
                <div className='list-of-content__links'>
                    {linksList}
                </div>
            </div>
            {/* 
            <ShareComponent
                activeLang={lang}
                id={1}
                articlePath={articlePath}
            /> */}
        </section>
    )
}

export default ListOfContent