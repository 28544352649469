import customHeaders, { getSpace } from '../../common/headers';
import useGeneralStore from '../../store/general';
import no_task from './img/no-task.svg';
import close_icon from './img/close_icon(members).svg';
import time_icon from './img/time_icon.svg';
import noTasks from './img/no-tasks.svg';
import chat_big_icon from './img/chat_big_icon.svg';
import { TaskItem } from '../../components/TaskItem/TaskItem';
import { TooltipBlack } from '../../components/Tooltip/TooltipBlack/TooltipBlack';
import { ModalAddTask2 } from '../../components/Modal/ModalAddTask2/ModalAddTask2';
import { ModalDeleteCol } from '../../components/Modal/ModalDeleteCol/ModalDeleteCol';
import { ModalNewTaskList } from '../../components/Modal/ModalNewTaskList/ModalNewTaskList';
import { TooltipLightBlue } from '../../components/Tooltip/TooltipLightBlue/TooltipLightBlue';
import { ModalAddExecutor } from '../../components/Modal/ModalAddExecutor/ModalAddExecutor';
import { ModalListExecutor } from '../../components/Modal/ModalListExecutor/ModalListExecutor';
import { ModalTaskStatus } from '../../components/Modal/ModalTaskStatus/ModalTaskStatus';
import { ExecutorPlaceholder } from '../../components/OptionsExecutor/OptionsExecutor';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RavshanHttp, RavshanHttps } from '../../fetchUrls';
import { fromSnakeCase, toSnakeCase } from '../../common/mappers';
import { ReactComponent as ArrowDown } from './img/arrow_down.svg';
import { ReactComponent as PlusGreen } from './img/plus_green.svg';
import {
  ViewSwitch,
  ButtonBasic,
  DropDownSelector,
  InputDynamicWidth,
  Checkbox,
  TableBox,
} from '@lk-gtcom/ecomlab-components';
import { useDataTable } from '../../common/hooks';
import { Loader } from '../../common/components/Loader/Loader';
import ChatModal from '../../components/Modal/ChatModal/ChatModal';
import { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { ModalAddTaskExit } from '../../components/Modal/ModalAddTask2/ModalAddTaskExit/ModalAddTaskExit';
// import grab_zone from './img/bx-grid-vertical.svg'
import { ReactComponent as GrabZone } from './img/bx-grid-vertical.svg';
import { BordTask } from './BordTask/BordTask';
import './TaskList.scss';
import {
  addFiltersByDataObj,
  addFiltersToUrlQuery,
  getFiltersByUrlQuery,
  getDataObjByFilters,
} from '../../common/utils';
import { ColumnTask } from './ColumnTask/ColumnTask';
import { MoonLoader } from 'react-spinners';

const parse = require('html-react-parser');
export const TASK_EVENT_TYPE = 'Задача';
const task_permissible = [
  'В РАБОТЕ',
  'ГОТОВО',
  'НА ПРОВЕРКЕ',
  'НА СТОПЕ',
  'НОВЫЕ ЗАДАЧИ',
];
const btn_sort = [
  {
    label: 'A-Z',
    value: 'name',
  },
  {
    label: 'По дате создания',
    value: 'date',
  },
  {
    label: 'По приоритету',
    value: 'priority',
  },
];

const TaskList = ({ setSelectedProjectAbove }) => {
  const [iniitalQuery, setInitialQuery] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const FETCH_DELAY_MS = 1000;
  const navigate = useNavigate();
  const path = useParams()['*'];
  const client_id = localStorage.getItem('client_id');
  const [isModal, setIsModal] = useState(false);
  const [isChatModal, setIsChatModal] = useState(false);
  const [showModalNewTaskList, setShowModalNewTaskList] = useState(false);
  const [showModalAddMember, setShowModalAddMember] = useState(false);
  const [removableTaskList, setRemovableTaskList] = useState('');
  const [infoTask, setInfoTask] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState();
  const [projectName, setProjectName] = useState(
    sessionStorage.getItem('taskProjectName')
  );
  const [taskId, setTaskId] = useState(null);
  const eventId = sessionStorage.getItem('eventId');
  const email = localStorage.getItem('email');

  const [prevTasks, setPrevTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedExecutor, setSelectedExecutor] = useState([]);
  const [selectedCoExecutor, setSelectedCoExecutor] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [author, setAuthor] = useState(null);

  const [projectAttendees, setProjectAttendees] = useState([]);
  const [projectPreview, setProjectPreview] = useState([]);
  const [selectedProjectPreview, setSelectedProjectPreview] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [load, setLoad] = useState(false);
  const [loadTotal, setLoadTotal] = useState(true)

  const [actual, setActualElement] = useState(undefined);
  const [draggableColumn, setDraggableColumn] = useState('');
  const [columnDragStarted, setColumnDragStarted] = useState(false);
  const containerRef = useRef();

  const [columnNameIsUpdating, setColumnNameIsUpdating] = useState(false);
  const [columnsAreUpdating, setColumnsAreUpdating] = useState(true);
  const [selectedProject, setSelectedProject] = useState([]);
  const [isShowAllExecutor, setIsShowAllExecutor] = useState(false);

  const [isOpenActiveMenu, setIsOpenActiveMenu] = useState(false);
  const [isModalDeleteCol, setIsModalDeleteCol] = useState(false);
  const [isModalListExecutor, setIsModalListExecutor] = useState(false);
  const [isModalStatus, setIsModalStatus] = useState(false);

  const [updateCol, setUpdateCol] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const refHeader = useRef();
  const refContainer = useRef();
  const refScrollbar = useRef();
  const refTaskBox = useRef();
  const [positionElement, setPositionElement] = useState(0);
  const [bottom, setBottom] = useState(0);
  const [leftPosition, setLeftPosition] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [widthTaskList, setWidthTaskList] = useState(0);
  const [widtContainer, setWidtContainer] = useState(0);

  const [sort1, setSort1] = useState(null);
  const [currentSort, setCurrentSort] = useState(null);

  const [selectView, setSelectView] = useState('Tile-long');
  const [currentColumn, setCurrentColumn] = useState(null);
  const [isRead, setIsRead] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isShowAuthor, setIsShowAuthor] = useState(false);
  const [actualId, setActualId] = useState(-1)
  const [updateId, setUpdateId] = useState(-1)

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSorte } = setTableFuncs;
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const [isEditCol, setIsEditCol] = useState(false);
  const [isBlockKey, setIsBlockKey] = useState(false);
  const [totalTaskGeneral, setTotalTaskGeneral] = useState(0)
  const [isUpdateTotal, setIsUpdateTotal] = useState(false)
  const refsTotal = useRef([]);
  const refBoard = useRef()
  const [isOpen, setIsOpen] = useState(true)

  const [generalTotal, setGeneralTotal] = useState(0)
  const [countCol, setCountCol] = useState(0)

  const [totalEvent, setTotalEvent] = useState(0)
  const [totalProject, setTotalProject] = useState(0)
  const [loadTotalCount, setLoadTotalCount] = useState(false)

  // печать
  const [isVisibleCheckbox, setIsVisibleCheckbox] = useState(false)
  const [listPrint, setListPrint] = useState([])

  // Доска
  const [currentBord, setCurrentBord] = useState(null)

  useEffect(() => {
    if (countCol == columns.length) {
      setLoadTotal(false)
    }
  }, [countCol])

  const [query, setQuery] = useState('');

  const openTask = (e, eventId, project, taskId) => {
    sessionStorage.setItem('eventId', eventId);
    sessionStorage.setItem('taskProjectId', project ? project : taskId);
    setTaskId(project ? project : taskId);
    const urlParams = new URLSearchParams(window.location.search);
    if (!path.match(eventId)) {
      navigate(
        `/tasks/task-list/${taskId}/${eventId}${urlParams.toString() ? `?${urlParams.toString()}` : ''
        }`
      );
    }
    const el = e?.target;
    if (el && el?.closest('.btn-and-options-box')) {
      return;
    } else {
      fetchInfoTask(eventId);
    }
  };

  const dragStartHandler = (e, card) => {
    setActualElement(card);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
  };

  // Изменение статуса задачи(task_type)
  // done
  const fetchSetTaskType = (event_id, selected) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_task_type`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_task_type`;
    }

    const body_curr = JSON.stringify({
      event_id: event_id,
      task_type: selected,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then(res => res.json())
      .then(json => {
        // fetchAllColumns(0, +taskId)
      })
      .catch(err => console.error(err))
  }



  // // получение total
  const fetchGetTotal = (filter) => {

    setLoadTotalCount(true)

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_total_events`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_total_events`;
    }

    const executor =
      selectedExecutor?.length > 0 ? { executor: selectedExecutor } : {};
    const author = selectedAuthor?.length > 0 ? { author: selectedAuthor } : {};
    const co_executor =
      selectedCoExecutor?.length > 0 ? { co_executor: selectedCoExecutor } : {};
    const taskType =
      selectedStatus?.length > 0
        ? { task_type: selectedStatus?.map(({ value }) => value) }
        : {};

    const task_board_id = currentBord?.id ? { task_board_id: currentBord?.id } : {}

    const body_curr = JSON.stringify({
      ...executor,
      ...co_executor,
      ...author,
      ...taskType,
      project_id: +taskId,
      search: searchValue,
      unviewed: isRead,
      on_trash: isDelete,
      ...task_board_id
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const { events, projects } = json
        if (events) setTotalEvent(events)
        if (projects) setTotalProject(projects)
        setLoadTotalCount(false)
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchGetTotal()
  }, [
    selectedExecutor,
    selectedCoExecutor,
    selectedStatus,
    selectedStatus,
    taskId,
    searchValue,
    isRead,
    isDelete,
    currentBord?.id
  ]);

  const dropCardHandler = (e, status) => {
    e.preventDefault();
    if (!actual) return;
    if (+taskId === -1) return;
    setPrevTasks(tasks);
    const tasksWithNewStatus = tasks.map((el) =>
      el?.eventId === actual?.eventId
        ? { ...actual, taskType: { id: status.typeId, name: status.typeName } }
        : el
    );
    setTasks(tasksWithNewStatus);
    fetchSetTaskType(actual?.eventId, status?.typeId);
    // fetchUpdateTask(actual, status)
    setActualElement(false);
  };

  const dragStartColumn = (e, column) => {
    if (!e.target.getAttribute('column')) return;
    setDraggableColumn(column);
    setColumnDragStarted(true);
  };

  const dropColumnHandler = (e, dropPlace) => {
    e.preventDefault();
    e.target.classList.remove('hover');

    if (!draggableColumn) return;
    const currentPlace = draggableColumn.place;
    if (currentPlace < dropPlace) {
      if (currentPlace === dropPlace - 1) return;
      updateTaskListPlace(dropPlace - 1);
    } else {
      if (currentPlace === dropPlace) return;
      updateTaskListPlace(dropPlace);
    }
    setDraggableColumn(null);
    setColumnDragStarted(false);
  };

  const customDragScrollHandler = (event) => {
    const container = containerRef.current;
    const scrollZoneWidth = 100;

    const cursorX = event.clientX - container?.getBoundingClientRect().left;

    if (cursorX < scrollZoneWidth) {
      container.scrollLeft -= 2;
    } else if (cursorX > container?.offsetWidth - scrollZoneWidth) {
      container.scrollLeft += 2;
    }
  };

  const handleSearch = (value) => {
    setColumnsAreUpdating(true);
    setSearchValue(value);
  };

  // const tasksByStatus = useMemo(() => {
  //     const getTasks = (statusId) => tasks?.filter(t => t?.taskType?.id === statusId).map((task, ind) => {
  //         const { eventName, priority, endTime, executor, coExecutor, eventId, taskType, author, link } = task
  //         const _taskId = task?.taskId

  //         return (
  //             <div
  //                 key={JSON.stringify(task) + ind}
  //                 draggable={true}
  //                 onDragStart={e => {
  //                     e.stopPropagation()
  //                     dragStartHandler(e, task)
  //                 }}
  //                 onDragOver={e => dragOverHandler(e)}
  //                 onClick={e => {
  //                     let el = e.target

  //                     if (!el.closest('.context-more') && !el.closest('.blackout')) {
  //                         openTask(e, eventId, _taskId, taskId)
  //                     }
  //                 }}
  //             >
  //                 <TaskItem
  //                     isShowAuthor={isShowAuthor}
  //                     link={link}
  //                     author={author}
  //                     projectName={projectName}
  //                     title={eventName}
  //                     executor={executor}
  //                     coExecutor={coExecutor}
  //                     time={new Date(endTime).toLocaleDateString('ru-RU', { day: '2-digit', month: 'numeric', year: '2-digit' })}
  //                     priority={priority}
  //                     openTask={e => openTask(e)}
  //                     isOpenActiveMenu={isOpenActiveMenu}
  //                     setIsOpenActiveMenu={(e) => setIsOpenActiveMenu(e)}
  //                     fetchProjects={e => fetchProjects(taskId)}
  //                     id={eventId}
  //                     status={taskType?.name}
  //                     deleteTask={e => fetchDeleteTask(e)}
  //                 />
  //             </div>
  //         )
  //     })

  //     const result = {}
  //     columns.forEach(col => {
  //         result[col?.typeId] = getTasks(col?.typeId)
  //     })
  //     return result
  // }, [tasks, columns, isOpenActiveMenu, isShowAuthor])

  const checkAttendee = (id) => {
    const executor = selectedExecutor?.indexOf(id) > -1;
    const coexecutor = selectedCoExecutor?.indexOf(id) > -1;
    const author = selectedAuthor?.indexOf(id) > -1;

    return +executor + coexecutor + author;
  };

  const arr_projectAttendees = projectAttendees?.map(
    ({ id, name, login, color }, ind) => {
      return (
        <div
          className="selected-members"
          key={id}
          style={{
            cursor: 'pointer',
            border:
              selectedExecutor?.indexOf(id) !== -1
                ? '1px solid #51CA64'
                : '1px solid white',
            borderRadius: '50%',
            marginLeft: ind != 0 ? '-8px' : '',
            position: 'relative',
            zIndex: selectedExecutor?.indexOf(id) !== -1 ? 2 : 1,
          }}
          onClick={(e) => {
            setSelectedExecutor((prev) => {
              if (prev?.indexOf(id) === -1) {
                return [...prev, id];
              } else {
                return prev?.filter((el) => el !== id);
              }
            });
            setColumnsAreUpdating(true);
            setUpdateCol(true);
          }}
        >
          <ExecutorPlaceholder
            bkg={
              selectedExecutor?.length > 0 &&
                selectedExecutor?.indexOf(id) === -1
                ? '#DDE0DD'
                : ''
            }
            name={name}
            color={color}
            email={login}
          />
        </div>
      );
    }
  );

  const [isLoadInfoTask, setIsLoadInfoTask] = useState(false);

  const fetchInfoTask = (id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/get_event`;
    } else {
      url = `${RavshanHttp}/api/tasks/get_event`;
    }
    setIsLoadInfoTask(true);
    setIsModal(true);

    const getHeaders = {
      ...headers,
      'event-id': +id,
      active: true,
    };

    fetch(url, { headers: getHeaders })
      .then((res) => res.json())
      .then((json) => {
        setInfoTask(fromSnakeCase(json));
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadInfoTask(false));
  };

  const fetchTaskTypes = (id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_task_type_list`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_task_type_list`;
    }

    fetch(url, { headers: headers })
      .then((res) => res.json())
      .then((json) => {
        setStatusOptions(
          json?.map((el) => ({ label: el, value: el }))
        );
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadInfoTask(false));
  };

  useEffect(() => {
    if (taskId == -1) {
      fetchTaskTypes()
    }
  }, [taskId]);

  const fetchProjects = (taskId, abortController = new AbortController()) => {
    if (!taskId) return;

    setLoad(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v2/tasks/get_event_by_project_new`;
    } else {
      url = `${RavshanHttp}/api/v2/tasks/get_event_by_project_new`;
    }

    const executor =
      selectedExecutor?.length > 0 ? { executor: selectedExecutor } : {};
    const author = selectedAuthor?.length > 0 ? { author: selectedAuthor } : {};
    const co_executor =
      selectedCoExecutor?.length > 0 ? { co_executor: selectedCoExecutor } : {};
    const taskType =
      selectedStatus?.length > 0
        ? { task_type: selectedStatus?.map(({ value }) => value) }
        : {};

    const body = JSON.stringify({
      project_id: +taskId,
      ...executor,
      ...co_executor,
      ...author,
      ...taskType,
      unviewed: isRead,
      order_param: currentSort?.value ? currentSort?.value : 'date',
      order_param_direction: sort1 ? sort1 : 'DESC',
      search: searchValue,
      on_trash: isDelete,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        const { project_name, author, attendee, task_type_list } = json;
        setProjectName(project_name ? project_name : 'По сотрудникам');
        setAuthor(author);
        // setStatusOptions(
        //   task_type_list?.map((el) => ({ label: el, value: el }))
        // );
        setProjectAttendees(
          attendee.sort((a, b) => checkAttendee(b.id) - checkAttendee(a.id))
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoad(false);
      });
  };



  const fetchGetAttendees = (abortController = new AbortController()) => {
    if (!taskId) return;

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_attendees/${taskId}`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_attendees/${taskId}`;
    }

    fetch(url, { method: 'GET', headers, signal: abortController.signal })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        // const { project_name, author, events, attendee, task_type_list } = json
        // setProjectName(project_name ? project_name : 'По сотрудникам')
        // setAuthor(author)
        // setTasks(events?.map(e => fromSnakeCase(e)).filter(e => e.eventType === TASK_EVENT_TYPE))
        json?.forEach((el) => {
          const { login, color, name } = el;
          if (login === email) {
            localStorage.setItem('currentUserColor', color);
            localStorage.setItem('currentUserName', name);
          }
        });
        // setStatusOptions(task_type_list?.map(el => ({ label: el, value: el })))
        setProjectAttendees(
          json.sort((a, b) => checkAttendee(b.id) - checkAttendee(a.id))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchGetAttendees();
  }, [taskId]);

  const fetchProjectsAttendee = (taskId) => {
    if (!taskId) return;

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/project_client_team`;
    } else {
      url = `${RavshanHttp}/api/tasks/project_client_team`;
    }

    const getHeaders = {
      ...headers,
      'project-id': +taskId,
    };

    fetch(url, { headers: getHeaders })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        setProjectAttendees(
          json.sort((a, b) => checkAttendee(b.id) - checkAttendee(a.id))
        );
      })
      .catch((err) => console.error(err));
  };

  const fetchAllColumns = (
    delayMs,
    taskId,
    abortController = new AbortController()
  ) => {
    if (!taskId) return;
    setColumnsAreUpdating(true);
    setCountCol(0)
    setLoadTotal(true)
    const url = `${RavshanHttps}/api/v3/tasks/get_task_type`;
    const executor =
      selectedExecutor?.length > 0 ? { executor: selectedExecutor } : {};
    const author = selectedAuthor?.length > 0 ? { author: selectedAuthor } : {};
    const coexecutor =
      selectedCoExecutor?.length > 0 ? { co_executor: selectedCoExecutor } : {};
    const taskTypeValue = selectedStatus?.map(({ value }) => value);
    const taskType = taskTypeValue ? { task_type: taskTypeValue } : {};

    const task_board_id = currentBord?.id ? { task_board_id: currentBord?.id } : {}

    const body = JSON.stringify({
      project_id: +taskId,
      ...executor,
      ...author,
      ...coexecutor,
      ...taskType,
      unviewed: isRead,
      search: searchValue,
      on_trash: isDelete,
      ...task_board_id
    });

    const fetchCallback = () =>
      fetch(url, {
        method: 'POST',
        headers,
        body,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('bad columns response');
          }
        })
        .then((json) => {
          if (!Array.isArray(json)) return;
          setColumns(
            json.sort((a, b) => a.place - b.place).map((c) => fromSnakeCase(c))
          );
          setGeneralTotal(0)
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setColumnsAreUpdating(false);
        });

    if (delayMs) setTimeout(fetchCallback, delayMs);
    else fetchCallback();
  };


  const fetchDeleteTask = (id, on_trash) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/delete_event`;
    } else {
      url = `${RavshanHttp}/api/tasks/delete_event`;
    }

    const body = JSON.stringify({
      event_id: id,
      on_trash: on_trash,
    });

    fetch(url, { body, method: 'DELETE', headers })
      .then(() => {
        // setTasks([])
        setIsModal(false)
        // setColumnsAreUpdating(true)
        //  fetchAllColumns(false, +taskId)
        // fetchProjectPreview()
        // fetchProjects(+taskId)
      })
      .catch(err => console.error(err))
  }

  const addNewTaskList = (name) => {
    const url = `${RavshanHttps}/api/tasks/post_task_type`;
    const body = JSON.stringify({
      name,
      place: columns.length + 1,
      task_id: +taskId,
      task_board_id: currentBord?.id
    });
    fetch(url, { body, method: 'POST', headers }).then((response) => {
      if (response.ok) {
        fetchAllColumns(false, +taskId);
        fetchProjects(+taskId);
      } else {
        throw new Error('bad create new task list response');
      }
    });
  };

  const updateTaskListPlace = (newPlace) => {
    const url = `${RavshanHttps}/api/tasks/change_task_type_position`;
    const body = JSON.stringify({
      place: newPlace,
      task_id: +taskId,
      type_id: draggableColumn.typeId,
    });
    fetch(url, { body, method: 'POST', headers }).then((response) => {
      if (response.ok) {
        fetchAllColumns(500, +taskId);
        fetchProjects(+taskId);
        setUpdateCol(true);
      } else {
        throw new Error('bad update task list place response');
      }
    });
  };

  const changeTaskListName = (column, newName) => {
    setColumnNameIsUpdating(true);
    const url = `${RavshanHttps}/api/tasks/put_task_type`;
    const body = JSON.stringify({
      place: column.place,
      name: newName,
      task_id: +taskId,
      type_id: column.typeId,
    });
    fetch(url, { body, method: 'PUT', headers })
      .then((response) => {
        if (response.ok) {
          fetchAllColumns(false, +taskId);
          fetchProjects(+taskId);
        } else {
          throw new Error('bad update task list response');
        }
      })
      .finally(() => setColumnNameIsUpdating(false));
  };

  const openModal = (column) => {
    setIsNew(true);
    setSelectedColumn(column);
    setIsModal(true);
  };

  const addNewMember = (attendee_id) => {
    const project_id = +taskId;
    const url = `${RavshanHttps}/api/tasks/add_attendee_in_project`;

    const body = JSON.stringify({
      attendee_id,
      project_id,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        fetchProjectsAttendee(project_id);
      })
      .catch((err) => console.error(err));
  };

  const fetchProjectPreview = (
    taskId,
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/get_project_preview`;
    } else {
      url = `${RavshanHttp}/api/tasks/get_project_preview`;
    }

    fetch(url, { headers, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProjectPreview(
          [{ name: 'По сотрудникам', id: -1, author: undefined }, ...json]?.map(
            ({ id, name, author }) => ({ label: name, value: { id, author } })
          )
        );
        if (!projectName & taskId) {
          const projectName = json.filter(({ id }) => id === +taskId)[0]?.name;
          sessionStorage.setItem(
            'taskProjectName',
            projectName
              ? projectName
              : taskId === -1
                ? 'По сотрудникам'
                : 'Не найдено'
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchProjectToRedirect = (projectId) => {
    const url = `${RavshanHttps}/api/tasks/get_project`;

    const body = JSON.stringify({
      executor: selectedExecutor,
      search: '',
    });

    fetch(url, { body, headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(err);
        }
      })
      .then((projects) => {
        const project = projects?.filter((el) => el?.task_id === +projectId)[0];
        if (project) {
          const { name, task_id } = project;
          setSelectedProject(project);
          sessionStorage.setItem(
            'taskProjectName',
            name ? name : taskId === -1 ? 'По сотрудникам' : 'Не найдено'
          );
          window.location.assign(`/tasks/task-list/${task_id}`);
        }
      })
      .catch((err) => console.error(err));
  };

  const deleteTaskList = () => {
    const url = `${RavshanHttps}/api/tasks/delete_task_type`;
    const body = JSON.stringify({
      task_id: +taskId,
      type_id: removableTaskList.typeId,
    });
    fetch(url, { body, method: 'DELETE', headers }).then((response) => {
      if (response.ok) {
        fetchAllColumns(false, +taskId);
        fetchProjectPreview();
        fetchProjects(+taskId);
      } else {
        throw new Error('bad delete task list response');
      }
    });
  };

  const fetchTableData = (params) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }
    setTableLoading(true);
    setTableData([]);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/get_event_by_project/table`;
    } else {
      url = `${RavshanHttp}/api/tasks/get_event_by_project/table`;
    }

    const body = JSON.stringify({
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      order_param: sort?.[0],
      executor: 0,
      order_param_direction: sort?.[1],
      filters: filtersParam,
    });

    fetch(url, { body, method: 'POST', headers }).then((res) =>
      res.json().then((json) => {
        json?.data?.map((el) => {
          if (el[3] && typeof el[3] === 'string') {
            el[3] = parse(el[3]);
          }
          return el;
        });
        setTable(json);
      })
    );
  };

  useEffect(() => {
    if (selectView == 'Table') {
      fetchTableData([]);
    }
  }, [selectView, paginatorRef, selectedSpace]);

  useEffect(() => {
    const paths = path?.split('/');
    if (paths) {
      const [project, task] = paths;
      if (project) {
        sessionStorage.setItem('taskProjectId', project);
        setTaskId(project);
      }
      if (!projectName) {
        fetchProjectPreview(+project);
      }
      if (task) {
        sessionStorage.setItem('eventId', task);
        openTask(null, task, +project, taskId);
        if (project) {
          fetchProjects(+project);
          fetchAllColumns(false, +project);
        }
      }
      return;
    }
    if (!taskId) {
      navigate('/tasks/project-for-tasks');
      return;
    } else {
      if (eventId) {
        navigate(`/tasks/task-list/${taskId}/${eventId}`);
      }
    }
  }, [selectedSpace]);

  useEffect(() => {
    if (taskId) {
      const abortController = new AbortController();
      fetchProjects(taskId, abortController);
    }
  }, [taskId]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!isModal || columns?.length < 1) {
      //   setLoad(true)
      setTimeout(() => {
        fetchAllColumns(false, taskId, abortController);
        fetchProjectPreview(taskId, abortController);
      }, FETCH_DELAY_MS);

      if (updateCol) {
        setUpdateCol(false)
      }
    }
    return () => abortController.abort()
  }, [
    selectedSpace,
    showModalAddMember,
    searchValue,
    updateCol,
    taskId,
    sort1,
    currentSort,
    isRead,
    isDelete,
    searchValue,
    currentBord
  ])

  useEffect(() => {
    if (!isModal) {

    }
  }, [isModal]);

  useEffect(() => {
    if (iniitalQuery) {
      const dataObj = getFiltersByUrlQuery(searchParams);
      addFiltersByDataObj({
        dataObj,
        setSelectedAuthor,
        setSelectedExecutor,
        setSelectedCoExecutor,
        setCurrentSort,
        setSelectedStatus,
        setSearchValue,
        setSort1,
        setCurrentBord
      });
      setInitialQuery(false);
    }
  }, [searchParams]);

  //Запись
  useEffect(() => {
    if (iniitalQuery) return;
    const dataObj = getDataObjByFilters({
      selectedAuthor,
      selectedCoExecutor,
      selectedExecutor,
      selectedStatus,
      currentSort,
      searchValue,
      sort1,
      currentBord
    });
    addFiltersToUrlQuery(dataObj, setSearchParams);
  }, [
    selectedAuthor,
    selectedCoExecutor,
    selectedExecutor,
    selectedStatus,
    currentSort,
    searchValue,
    sort1,
    currentBord
  ]);



  useEffect(() => {
    const id = selectedProjectPreview?.value?.id;
    if (id) {
      fetchProjectToRedirect(id);
    }
  }, [selectedProjectPreview, selectedSpace]);

  const handleScroll = () => {
    const container = refContainer.current;
    const headerr = refHeader.current;
    const header = document.querySelector('.col-task__white');
    const top = container.getBoundingClientRect().top;
    const bottom = container.getBoundingClientRect().top;

    let height;
    if (header) {
      height = header.getBoundingClientRect().height;
    }

    if (top < 0) {
      setPositionElement(-top);
    } else {
      setPositionElement(0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const observer = new ResizeObserver((entries) => {
    const { width } = entries[0].contentRect;
    setWidthTaskList(width);
  });

  useEffect(() => {
    if (refContainer?.current) {
      observer.observe(refContainer?.current);
    }
  }, [refContainer, observer]);

  // Синхронизируем скролл
  const positionScroll = () => {
    if (refContainer?.current && refScrollbar?.current) {
      refContainer.current.scrollLeft = refScrollbar.current.scrollLeft;
    }
  };

  const scrollBottom = () => {
    positionScroll();
    const left = refContainer?.current?.getBoundingClientRect().left;
    // const width = refContainer?.current?.scrollWidth;
    const width = (columns?.length * 240)
    const scrollWidth = refTaskBox?.current?.scrollWidth;

    // Ширина таблицы
    const widthTaskList = refContainer?.current?.getBoundingClientRect().width;
    setWidthTaskList(widthTaskList);
    setLeftPosition(left); //left
    // setWidtContainer(width); //right
    //  setScrollWidth(scrollWidth) //width

    // Видимость скроллбара
    const heightDocument = document.documentElement.clientHeight;
    setBottom(refContainer?.current?.getBoundingClientRect().bottom);
    if (heightDocument) {
      const pointBottom =
        refContainer?.current?.getBoundingClientRect().bottom >
        document.documentElement.clientHeight;
      const pointTop = refContainer?.top > 0;
      setIsFixed(pointBottom || pointTop ? true : false);
    }

    //верхняя точка скроллбара
    if (heightDocument) {
      const pointTop =
        refContainer?.current?.getBoundingClientRect().top >
        document.documentElement.clientHeight;
      setIsVisible(pointTop ? false : true);
    }
  };

  // // скролл таблицы shift + wheel
  const scrollWheel = (e) => {
    const isShift = e?.shiftKey;
    if (refContainer?.current && refScrollbar?.current && isShift) {
      refScrollbar.current.scrollLeft = refContainer.current.scrollLeft;
    }
  };

  // // Скролл кнопками
  const scrollKey = (e) => {

    //  refContainer?.current?.scrollLeft = refScrollbar?.current.scrollLeft

    if (!isModal & !isBlockKey) {
      const left = 37;
      const right = 39;

      if (e?.keyCode === left || e?.keyCode === right) {
        refContainer?.current?.focus();
      }
    }

    if (refContainer?.current && refScrollbar?.current && !isBlockKey) {
      refScrollbar.current.scrollLeft = refContainer.current.scrollLeft;
    }
  };

  // useEffect(() => {
  //   if (refContainer?.current && refScrollbar?.current && !isBlockKey) {
  //     refScrollbar.current.scrollLeft = refContainer.current.scrollLeft;
  //   }
  // }, [isBlockKey]);




  useEffect(() => {
    const widthTaskList = (columns?.length * 256) + (isOpen ? 210 : 48);
    setWidtContainer(widthTaskList);
  }, [columns, isOpen]);

  useEffect(() => {
    window.addEventListener('scroll', scrollBottom);
    window.addEventListener('resize', scrollBottom);
    // window?.addEventListener('keydown', scrollKey);
    refContainer?.current?.addEventListener('wheel', scrollWheel);

    return () => {
      window.removeEventListener('scroll', scrollBottom);
      window.removeEventListener('resize', scrollBottom);
      // window?.removeEventListener('keydown', scrollKey);
      refContainer?.current?.removeEventListener('wheel', scrollWheel);
    };
  }, [isModal]);

  useEffect(() => {
    // refContainer?.current?.focus();
    window?.addEventListener('keydown', scrollKey);

    return () => {
      window?.removeEventListener('keydown', scrollKey);
    };
  }, [
    refContainer?.current,
    columns?.length,
    isModal,
    showModalAddMember,
    showModalNewTaskList,
    isModalListExecutor,
    isModalDeleteCol,
    isChatModal,
    isBlockKey
  ]);

  useEffect(() => {
    positionScroll();
    scrollBottom();
  }, [refTaskBox?.current]);

  useEffect(() => {
    positionScroll();
    scrollBottom();
  }, [tasks]);


  useEffect(() => {
    const params = new URL(document.location).searchParams;
    let query = params.get('filter');
    setQuery(query);
    if (query == 'my_project') {
      setSelectedAuthor([Number(client_id)]);
    }
  }, []);

  const [isModalExit, setIsModalExit] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [currPrjoect, setCurrPrjoect] = useState(false);

  useEffect(() => {
    if (currPrjoect) {
      const urlParams = new URLSearchParams(window.location.search);
      window.location.assign(
        `/tasks/task-list/${currPrjoect?.id}${urlParams.toString() ? `?${urlParams.toString()}` : ''
        }`
      );
      setCurrPrjoect(false);
    }
  }, [currPrjoect]);



  return (
    <>
      {/* Новая модалка СПИСОК СОТРУДНИКОВ без логики */}
      {showModalAddMember && <ModalAddExecutor
        projectAttendees={projectAttendees?.map(el => el?.id)}
        addNewMember={name => addNewMember(name)}
        close={() => setShowModalAddMember(false)}
        projectId={taskId} />}

      <div className='task-list'>
        {showModalNewTaskList && <ModalNewTaskList add={name => addNewTaskList(name)} close={() => setShowModalNewTaskList(false)} />}

        {isModalListExecutor && <ModalListExecutor
          setColumnsAreUpdating={e => setColumnsAreUpdating(e)}
          executors={projectAttendees}
          setIsModal={(e) => setIsModalListExecutor(e)}
          selectedExecutor={selectedExecutor}
          selectedCoExecutor={selectedCoExecutor}
          selectedAuthor={selectedAuthor}
          setSelectedAuthor={(e) => setSelectedAuthor(e)}
          checkAttendee={checkAttendee}
          setSelectedExecutor={e => setSelectedExecutor(e)}
          setSelectedCoExecutor={e => setSelectedCoExecutor(e)}
          updateData={e => {
            fetchAllColumns(false, +taskId)
            fetchProjects(+taskId)
            fetchProjectPreview()
          }}
        />}
        {/* {showModalAddMember && <ModalAddTaskProjectMember addNewMember={name => addNewMember(name)} close={() => setShowModalAddMember(false)} projectId={taskId} />} */}

        {isModalDeleteCol && (
          <ModalDeleteCol
            deleteTask={(e) => deleteTaskList(e)}
            listName={removableTaskList.typeName}
            setIsModal={(e) => setIsModalDeleteCol(e)}
          />
        )}

        {isModalStatus && (
          <ModalTaskStatus
            columns={columns}
            currentColumn={currentColumn}
            setIsModal={(e) => setIsModalStatus(e)}
          />
        )}

        {isModal && <ModalAddTask2
          isNew={isNew}
          event_id={eventId}
          setIsNew={setIsNew}
          setIsModal={(e) => setIsModal(e)}
          setIsModalExit={setIsModalExit}
          infoTask={infoTask}
          isLoadInfoTask={isLoadInfoTask}
          attendees={projectAttendees}
          taskId={taskId}
          setInfoTask={e => setInfoTask(e)}
          setTasks={e => setTasks(e)}
          selectedColumn={selectedColumn}
          allColumns={projectPreview}
          query={query}
          fetchDeleteTask={e => {
            fetchDeleteTask(e)
          }}
          searchParams={searchParams}
          setUpdateId={setUpdateId}
          setActualId={setActualId}
        />}



        {
          isChatModal &&
          <ChatModal
            setIsModal={setIsChatModal}
          />
        }

        <div className="task-list__top-info">
          <div className="category-breadcrumbs__content">
            <p
              onClick={(e) => {
                sessionStorage.removeItem('taskProjectId');
                setTaskId(null);
                sessionStorage.removeItem('taskProjectName');
                setSelectedProjectAbove(null);
                navigate('../project-for-tasks');
              }}
              className="category-breadcrumbs__item"
            >
              Проекты
            </p>
            <p
              className="category-breadcrumbs__item"
              style={{ position: 'relative', zIndex: 7 }}
            >
              <DropDownSelector
                state={[{ label: projectName, value: taskId }]}
                options_prop={projectPreview}
                setState={(project) => {
                  if (project?.label) {
                    const { label, value } = project;
                    sessionStorage.setItem('taskProjectName', label);
                    setCurrPrjoect(value);
                    setSelectedStatus([]);
                  }
                }}
                onBlur={(e) => refContainer?.current?.focus()}
              />
            </p>
          </div>

          <div className="task-list__sum-tasks">
            {+taskId === -1 && (
              <p className="task-list__sum-text">
                Всего проектов: <span className='task-list__sum-text_span'>
                  {loadTotalCount ? <MoonLoader size={10} /> : (totalProject || 0)}
                  {/* {columns?.length || 0} */}
                </span>
              </p>
            )}
            <p className="task-list__sum-text">
              Всего задач: {<span className='task-list__sum-text_span'>
                {loadTotalCount ? <MoonLoader size={10} /> : (totalEvent || 0)}
              </span>}
            </p>
          </div>
        </div>

        <div className="toolbar-top">
          <div className="left-box">
            <div className="members-box">
              <TooltipLightBlue
                child={
                  <div
                    className="btn-add-executor"
                    onClick={(e) => {
                      setShowModalAddMember(true)
                    }}
                  ></div>
                }
                text="Добавить участника"
                top="50%"
                left="100%"
              />

              {selectView == 'Tile-long' && (
                <>
                  {isShowAllExecutor
                    ? arr_projectAttendees
                    : arr_projectAttendees?.splice(0, 6)}

                  {selectedExecutor?.length > 0 && (
                    <button
                      className="btn-close"
                      onClick={(e) => {
                        setSelectedExecutor([]);
                        setUpdateCol(true);
                      }}
                    >
                      <img src={close_icon} />
                    </button>
                  )}

                  {
                    <p
                      className="sum_executor"
                      onClick={(e) => setIsModalListExecutor(true)}
                    >
                      +
                      <span>
                        {projectAttendees?.length > 7
                          ? projectAttendees?.length - 6
                          : ''}
                      </span>
                    </p>
                  }
                </>
              )}
            </div>
            {selectView == 'Tile-long' && (
              <>
                {+taskId === -1 && (
                  <DropDownSelector
                    placeholder="Статус"
                    isClearable={true}
                    options_prop={statusOptions}
                    state={selectedStatus}
                    setState={(e) => {
                      setSelectedStatus(e);
                    }}
                    multi
                    onBlur={(e) => {
                      fetchAllColumns(false, +taskId);
                      fetchProjects(+taskId);
                      fetchProjectPreview();
                      refContainer?.current?.focus();
                    }}
                  />
                )}

                <div className="tab-sort">
                  {btn_sort.map((el) => (
                    <button
                      className={
                        currentSort?.label == el?.label ? 'btn_active' : 'btn'
                      }
                      key={el?.label}
                      onClick={(e) => {
                        if (currentSort?.label == el?.label) {
                          setSort1(sort1 == 'ASC' ? 'DESC' : 'ASC');
                        } else {
                          setSort1('ASC');
                          setCurrentSort(el);
                        }
                        refContainer?.current?.focus();
                      }}
                    >
                      {el?.label}
                      <ArrowDown
                        className={
                          currentSort?.label == el?.label
                            ? sort1 == 'ASC'
                              ? 'arrow'
                              : 'arrow_up'
                            : 'arrow'
                        }
                        style={
                          currentSort?.label == el?.label
                            ? { stroke: 'white' }
                            : { stroke: '#64748B' }
                        }
                      />
                    </button>
                  ))}
                </div>
              </>
            )}
            <Checkbox value={isRead} onChange={(checked) => {
              setIsRead(checked)
              refContainer?.current?.focus();
            }}>
              Показать непрочитанные
            </Checkbox>

            <Checkbox
              value={isShowAuthor}
              onChange={(checked) => {
                setIsShowAuthor(!isShowAuthor)
                refContainer?.current?.focus();
              }}
            >
              Показать создателя
            </Checkbox>

            <Checkbox
              value={isDelete}
              onChange={(checked) => {
                setIsDelete(checked)
                refContainer?.current?.focus();
              }}
            >
              В корзине
            </Checkbox>

            <ButtonBasic
              green
              text={!isVisibleCheckbox ? 'Выбрать для печати' : `Печать ${listPrint?.length}`}
              width='auto'
              onClick={() => {
                if (!isVisibleCheckbox) {
                  setIsVisibleCheckbox(true)
                } else {
                  alert(`Распечатать задачи ${listPrint}`)
                }
              }}
            />
          </div>

          <div className="right-filter-box">
            <InputDynamicWidth
              placeholder="Поиск задачи"
              position="left"
              maxWidth="100%"
              drop={false}
              value={searchValue}
              onChange={(e) => handleSearch(e)}
            />
            {taskId != -1 && author?.login == email && (
              <ButtonBasic
                whiteBlue
                onClick={() => setShowModalNewTaskList(true)}
                text={'Добавить колонку'}
                size="40px"
                width="220px"
                minWidth="160px"
              />
            )}

            <ViewSwitch
              tile={false}
              selectView={selectView}
              setSelectView={(e) => {
                setSelectView(e)
                refContainer?.current?.focus();
              }}
            />
          </div>
        </div>

        <div
          ref={refContainer}
          className="main-task-box"
          tabIndex={5}
          style={{
            outline: 'none',
            overflowX: isBlockKey ? 'hidden' : 'auto',
          }}
        >

          {+taskId != -1 && <BordTask
            taskId={taskId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setCurrentBordTask={setCurrentBord}
            ref={refBoard} />}

          {selectView == 'Tile-long' && (
            <div
              className={`task-list__content ${columnsAreUpdating ? 'loading' : ''
                }`}
              dragover={columnDragStarted.toString()}
              onDrag={(e) => customDragScrollHandler(e)}
              ref={refTaskBox}
            >
              {load ? (
                <Loader style={{ marginTop: '200px' }} />
              ) : (
                <>
                  {columns?.length > 0 &&
                    !load &&
                    columns
                      .sort((c1, c2) => c1.place - c2.place)
                      .map((col, ind) => (
                        <Fragment key={col.typeId}>
                          {
                            <div
                              className="task-list__hover-box"
                              onDragOver={(e) => {
                                if (author?.login == email) {
                                  e.preventDefault();
                                  setIsOver(ind); // Устанавливаем состояние при перетаскивании
                                }
                              }}
                              onDrop={(e) => {
                                if (draggableColumn) {
                                  if (author?.login == email) {
                                    dropColumnHandler(e, col.place);
                                    setIsOver(false);
                                  }
                                }
                              }}
                              onDragEnter={(e) => {
                                if (author?.login == email) {
                                  e.target.classList.add('hover');
                                  setIsOver(false);
                                }
                              }}
                              onDragLeave={(e) => {
                                if (author?.login == email) {
                                  e.target.classList.remove('hover');
                                  setIsOver(false); // Сбрасываем состояние при выходе
                                }
                              }}
                              style={{
                                border:
                                  isOver === ind
                                    ? '1px solid #51CA64'
                                    : 'transparent',
                              }}
                            >
                              <ColumnTask
                                typeId={col.typeId}
                                isShowAuthor={isShowAuthor}
                                dragStartColumn={dragStartColumn}
                                dragOverHandler={dragOverHandler}
                                dropCardHandler={dropCardHandler}
                                setIsOver={setIsOver}
                                positionElement={positionElement}
                                projectName={projectName}
                                col={col}
                                setIsEditCol={setIsEditCol}
                                isEditCol={isEditCol}
                                setIsBlockKey={setIsBlockKey}
                                changeTaskListName={changeTaskListName}
                                setCurrentColumn={setCurrentColumn}
                                setIsModalStatus={setIsModalStatus}
                                taskId={taskId}
                                setRemovableTaskList={setRemovableTaskList}
                                setIsModalDeleteCol={setIsModalDeleteCol}
                                openModal={openModal}
                                setSelectedColumn={setSelectedColumn}
                                setIsModal={setIsModal}
                                columnNameIsUpdating={columnNameIsUpdating}
                                fetchProjectToRedirect={fetchProjectToRedirect}
                                author={author}
                                email={email}
                                actual={actual}
                                refHeader={refHeader}
                                isOpenActiveMenu={isOpenActiveMenu}
                                // tasksByStatus={tasksByStatus}
                                dragStartHandler={dragStartHandler}
                                task_permissible={task_permissible}
                                fetchProjects={fetchProjects}
                                openTask={openTask}
                                fetchDeleteTask={fetchDeleteTask}
                                setIsOpenActiveMenu={setIsOpenActiveMenu}
                                selectedExecutor={selectedExecutor}
                                selectedAuthor={selectedAuthor}
                                selectedCoExecutor={selectedCoExecutor}
                                selectedStatus={selectedStatus}
                                setLoad={setLoad}
                                headers={headers}
                                body={{
                                  unviewed: isRead,
                                  order_param: currentSort?.value ? currentSort?.value : 'date',
                                  order_param_direction: sort1 ? sort1 : "DESC",
                                  search: searchValue,
                                  on_trash: isDelete,
                                  page: 1,
                                  limit: 100,
                                  executor: selectedExecutor?.length > 0 ? selectedExecutor : []
                                }}
                                actualId={actualId}
                                setActualId={setActualId}
                                updateId={updateId}
                                setUpdateId={setUpdateId}
                                setIsUpdateTotal={setIsUpdateTotal}
                                isUpdateTotal={isUpdateTotal}
                                refsTotal={refsTotal}
                                fetchAllColumns={() => { fetchAllColumns(false, +taskId) }}
                                refContainer={refContainer}
                                setGeneralTotal={setGeneralTotal}
                                setCountCol={setCountCol}
                                columns={columns}
                                searchValue={searchValue}
                                isVisibleCheckbox={isVisibleCheckbox}
                                listPrint={listPrint}
                                setListPrint={(e) => setListPrint(e)}
                              />
                            </div>
                          }
                          {/* {
                                                (ind === columns.length - 1)
                                                &&
                                                <div
                                                    className='column-drop-placeholder'
                                                    onDragOver={e => e.preventDefault()}
                                                    onDrop={e => dropColumnHandler(e, col.place + 1)}
                                                    onDragEnter={e => e.target.classList.add('hover')}
                                                    onDragLeave={e => e.target.classList.remove('hover')}
                                                >
                                                </div>
                                            } */}
                        </Fragment>
                      ))}

                  {columns?.length < 0 && !load && (
                    <div
                      style={{
                        width: '100%',
                        height: '60vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1.5em',
                      }}
                    >
                      <h2 style={{ fontSize: '48px', fontWeight: 500 }}>
                        Задачи не найдены
                      </h2>
                      <h3 style={{ fontSize: '20px', fontWeight: 500 }}>
                        Выберите другую доску с задачами
                      </h3>
                      <img
                        src={noTasks}
                        alt="no-tasks-image"
                        style={{ width: 485, height: 300 }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {selectView == 'Table' && (
            <TableBox
              {...tableProps}
              ref={paginatorRef}
              paginator={true}
              fetchCallback={(e) => fetchTableData(e)}
            />
          )}
        </div>


        {isVisible && (
          <div
            className="scrollbar"
            ref={refScrollbar}
            onScroll={(e) => {
              scrollBottom();
              positionScroll();
            }}
            style={{
              height: '16px',
              position: 'fixed',
              // bottom: isFixed ? 0 : '',
              bottom: 0,
              // top: !isFixed ? bottom : '',
              left: leftPosition + 'px',
              // top: bottom + 'px',
              // left: leftPosition + 'px',
              // right: widtContainer + 'px',
              maxWidth: widthTaskList + 'px',
              minWidth: widthTaskList + 'px',
              width: '100%',
              overflowX: isBlockKey ? 'hidden' : 'auto',
              overflowY: 'hidden',
            }}
          >
            <div
              style={{
                minWidth: widtContainer,
                maxWidth: widtContainer,
              }}
            ></div>
          </div>
        )}

        <button
          className="widget-chat"
          onClick={() => {
            setIsChatModal(true);
          }}
        >
          <img src={chat_big_icon} />
        </button>
      </div>
    </>
  );
};

export { TaskList };
