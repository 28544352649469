import { useEffect, useState } from 'react';
import { ButtonBasic, InputDinamycPlaceholder, DropDownSelector, TextareaDynamicPlaceholder } from '@lk-gtcom/ecomlab-components';
import close_icon from './img/close_icon.svg';
import done_icon from './img/done_icon.svg';
import { validationEmail } from './validationFunc';
import './ModalBitrixForm.scss';

const ModalBitrixForm = ({
  setIsModal,
  inputArr,
  formTitle,
  subTitle,
  bitrixToken,
  modal = true,
  colorBtn = 'green',
  btnName = false,
  activeLang = 'ru',
  communication = false,
}) => {
  const [inputValue, setInputValue] = useState({});
  const [selectedValue, setSelectedValue] = useState([]);
  const [err, setErr] = useState({});
  const [inputs, setInputs] = useState([]);
  const [successfulResponse, setsuccessfulResponse] = useState(false);

  const translateDoneTitle = () => {
    if (activeLang == 'en') return 'Your application is accepted!';
    if (activeLang == 'tr') return 'Başvurunuz kabul edildi!';
    return 'Заявку приняли!';
  };

  const translateDoneSubTitle = () => {
    if (activeLang == 'en') return 'We’ll contact you soon!';
    if (activeLang == 'tr') return 'En kısa sürede ilgileneceğiz!';
    return 'В ближайшее время свяжемся с вами!';
  };

  const translateBtn = () => {
    if (activeLang == 'en') return 'Send';
    if (activeLang == 'tr') return 'Gönder';
    return 'Отправить';
  };

  const fetchTest = () => {
    let tg;
    const current = Object.entries(inputValue).filter(([key, value]) => {
      if (key == 'FIELDS[TG]') {
        tg = value;
      }
      return key != 'FIELDS[TG]';
    });

    const queries = current
      ?.map(([key, value]) => {
        if (key == 'FIELDS[TITLE]') {
          return `${key}=${value + ' // ' + inputValue['FIELDS[NAME]']}`;
        }
        if (key == 'FIELDS[COMMENTS]') {
          if (selectedValue?.label) {
            if (communication) {
              return `${key}=Способ связи: ${selectedValue?.label}  // ${value}`;
            } else {
              return `${key}=Тип партнера: ${selectedValue?.label}  // ${value}`;
            }
          }

          // return selectedValue?.label
          //     ?
          //     `${key}=Тип партнера: ${selectedValue?.label}  // ${value}`
          //     :
          //     `${key}=${value}`
        } else return `${key}=${value}`;
      })
      ?.join('&');

    const testUrl = `https://ecs.bitrix24.ru/rest/1/${bitrixToken}/crm.lead.add.json?${queries}`;

    fetch(testUrl)
      .then((res) => res.json())
      .then((json) => {
        if (json?.result) {
          setsuccessfulResponse(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleChange = (e, id) => {
    // setInputValue(prev => ({ ...prev, [id]: e?.value }))
    setSelectedValue(e);
  };

  useEffect(() => {
    setInputs([
      ...inputArr?.map(
        (
          {
            id,
            placeholder,
            hidden,
            value,
            inputType,
            options_prop,
            type,
            required,
          },
          ind
        ) => {
          if (hidden) {
            setInputValue((prev) => ({ ...prev, [id]: value }));
            return;
          }

          if (type == 'textarea') {
            return (
              <TextareaDynamicPlaceholder
                width="100%"
                changeValue={inputValue?.[id]}
                onChange={(e) =>
                  setInputValue((prev) => ({ ...prev, [id]: e }))
                }
                required={required}
                placeholder={placeholder}
              />
            );
          }

          if (type == 'dropdown') {
            return (
              <DropDownSelector
                options_prop={options_prop}
                state={selectedValue}
                setState={(e) => {
                  handleChange(e, id);
                }}
                err={err?.[id]}
                required={required}
                placeholder={placeholder}
                size="l"
                width="100%"
              />
            );
          }

          if (type == 'input') {
            return (
              <InputDinamycPlaceholder
                key={id}
                type={inputType ? inputType : 'text'}
                placeholder={placeholder}
                changeValue={inputValue?.[id]}
                onChange={(e) =>
                  setInputValue((prev) => ({ ...prev, [id]: e }))
                }
                autoComplete={false}
                err={err?.[id]}
                required={required}
                textErr={err?.[id]}
                mask={inputType === 'tel' ? '+{7} (000) 000-00-00' : ''}
              />
            );
          }
        }
      ),
    ]);
  }, [inputArr, err, selectedValue]);

  const validationFields = () => {
    setErr({});
    const error = {};
    inputArr?.forEach(({ id, inputType, required }, ind) => {
      if (required) {
        if (inputType == 'email') {
          if (!validationEmail(inputValue?.[id])) {
            error[id] = 'Неверный email';
          }
        } else if (inputType == 'tel') {
          const regex = /^[\d\+][\d\(\)\ -]{4,14}\d$/;
          if (!regex.test(inputValue?.[id])) {
            error[id] = 'Неверный телефон';
          }
        } else if (inputType == 'dropdown') {
          if (!selectedValue?.label) {
            error[id] = 'Выберите значение';
          }
        } else if (inputType == 'text') {
          if (inputValue?.[id].length < 1) {
            error[id] = 'Поле не заполнено';
          }
        }
      }
      setErr(error);
    });

    if (Object.keys(error).length === 0) {
      return true;
    } else return false;
  };

  return (
    <div
      className={modal ? 'blackout' : ''}
      onClick={(e) => {
        if (modal) {
          let el = e.target;
          if (el.classList.contains('blackout')) {
            setIsModal(false);
          }
        }
      }}
    >
      <div className="modal-bitrix-form">
        {successfulResponse && (
          <div className="modal-bitrix-form__successful">
            <img
              className="modal-bitrix-form__icon-done"
              alt="icon"
              src={done_icon}
            />
            <p className="modal-bitrix-form__text-green">
              {translateDoneTitle()}
            </p>
            <p className="modal-bitrix-form__text">{translateDoneSubTitle()}</p>
          </div>
        )}
        <>
          {(formTitle || subTitle) && (
            <header className="modal-bitrix-form__header">
              <h1 className="modal-bitrix-form__title">{formTitle}</h1>
              <p className="modal-bitrix-form__description">{subTitle}</p>
              {modal && (
                <button
                  className="modal-bitrix-form__btn-close"
                  onClick={(e) => setIsModal(false)}
                >
                  <img src={close_icon} />
                </button>
              )}
            </header>
          )}
          <div className="modal-bitrix-form__content">{inputs}</div>
          <ButtonBasic
            text={btnName ? btnName : translateBtn()}
            green={colorBtn == 'green'}
            darkViolet={colorBtn == 'darkViolet'}
            width="100%"
            size="56px"
            onClick={(e) => {
              if (validationFields()) {
                fetchTest();
              }
            }}
          />
        </>

        {/* <script data-b24-form="inline/91/e22x0o" data-skip-moving="true">{(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b17903668/crm/form/loader_91.js')}</script> */}
      </div>
    </div>
  );
};

export { ModalBitrixForm }
