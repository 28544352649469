import { useState } from 'react'
import { ButtonBasic, InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import './ModalSelectionSocialMedia.scss'

const social_list_rus = [
    {
        name: 'Telegram',
        img: "https://dl.ecomru.ru:9001/dev-images/tg.svg",
        id: 1
    },
    {
        name: 'WhatsApp',
        img: "https://dl.ecomru.ru:9001/dev-images/wtsp.svg",
        id: 2
    },
    {
        name: 'Одноклассники',
        img: "https://dl.ecomru.ru:9001/dev-images/ok.svg",
        id: 3
    },
    {
        name: 'ВКонтакте',
        img: "https://dl.ecomru.ru:9001/dev-images/vk.svg",
        id: 4
    },
    {
        name: 'X (Twitter)',
        img: "https://dl.ecomru.ru:9001/dev-images/xcom.svg",
        id: 5
    },
    {
        name: 'Linkedin',
        img: "https://dl.ecomru.ru:9001/dev-images/linkedin.svg",
        id: 6
    },
    {
        name: 'Facebook',
        img: "https://dl.ecomru.ru:9001/dev-images/facebook.svg",
        id: 7
    },
    {
        name: 'Дзен',
        img: "https://dl.ecomru.ru:9001/dev-images/dzen.svg",
        id: 8
    },
]

const social_list_eng = [
    {
        name: 'X (Twitter)',
        img: "https://dl.ecomru.ru:9001/dev-images/xcom.svg",
        id: 4
    },
    {
        name: 'Linkedin',
        img: "https://dl.ecomru.ru:9001/dev-images/linkedin.svg",
        id: 5
    },
    {
        name: 'Facebook',
        img: "https://dl.ecomru.ru:9001/dev-images/facebook.svg",
        id: 6
    },
]

const list_media = {
    'facebook': 'facebook',
    'dzen.ru': 'dzen',
    'instagram.com': 'instagram',
    'linkedin.com': 'linkedin',
    'vc.ru': 'vc.ru',
    'ok.ru': 'ok',
    'pikabu.ru': 'pikabu',
    't.me': 'telegram',
    'vk.com': 'vk',
    'whatsapp.com': 'whatsapp',
    'x.com': 'x'

}

const ModalSelectionSocialMedia = ({ setIsModal, activeLang = 'ru', inputValue, setInputValue }) => {

    const current_list = activeLang == 'ru' ? social_list_rus : social_list_eng
    const [insideValue, setInsideValue] = useState('')

    const onChangeLink = (e) => {
        setInsideValue(e)
    }

    const saveValue = (value) => {
        let current;
        for (let key in list_media) {
            if (value.includes(key)) {
                current = list_media[key]
            }
            if(current) {
                setInputValue({...inputValue, [current]: insideValue})
            }
        }
    }

    return (
        <div className='modal-selection-social-media'>

            <InputDinamycPlaceholder
                placeholder='Введите ссылку'
                onChange={e => onChangeLink(e)}
                size='s'
            />

            <ButtonBasic
                green
                text='+'
                minWidth='40px'
                width='40px'
                size='40px'
                onClick={(e) => {
                    saveValue(insideValue)
                    setIsModal(false)
                }}
            />
        </div>
    )
}

export { ModalSelectionSocialMedia }