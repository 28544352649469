import { Link } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import minus_icon from '../img/bx-minus.svg'
import useGeneralStore from '../../../../store/general';
import { ConnectionsContext} from '../../../../App'
import customHeaders, { getSpace } from '../../../../common/headers';
import { ButtonActionDrop } from '../../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { OlegAccountHttps, OlegAccountHttp } from '../../../../fetchUrls';
import { setAccountId, setName, setServiceData } from '../../../SettingsConnection/actions';
import useModal from '../../../../store/modal';
import { useShallow } from 'zustand/react/shallow';
import { useContext } from 'react';
import './SettingsRow.scss'
import { useState } from 'react';

const SettingsRow = ({
    id,
    icon,
    name,
    mp_name,
    mp_group,
    isAdmin,
    key,
    btnFunc,
    setIsModal,
    setConnectedModal
}) => {
    const { dispatch } = useContext(ConnectionsContext);
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [role, setRole] = useState('')
    const {
        isModalActive,
        setIsModalActive,
        isServiceSelected,
        setIsServiceSelected,
        isServiceConnected,
        setIsServiceConnected,
        fetchedServiceData,
        setFetchedServiceData,
        setIsCurrentArticle,
    } = useModal(
        useShallow((state) => ({
            isModalActive: state.isModalActive,
            setIsModalActive: state.setIsModalActive,
            isServiceSelected: state.isServiceSelected,
            setIsServiceSelected: state.setIsServiceSelected,
            isServiceConnected: state.isServiceConnected,
            setIsServiceConnected: state.setIsServiceConnected,
            fetchedServiceData: state.fetchedServiceData,
            setFetchedServiceData: state.setFetchedServiceData,
            setIsCurrentArticle: state.setIsCurrentArticle,
        }))
    );



    const onEditAccount = (account_id) => {
        if (account_id) {
            let url;
            if (!window.location.hostname.match('localhost')) {
                url = `${OlegAccountHttp}/api/v2/account/${account_id}`;
            } else {
                url = `${OlegAccountHttps}/api/v2/account/${account_id}`;
            }

            const currentHeaders = {
                ...headers,
                'account-id': account_id,
            };

            fetch(url, {
                method: 'GET',
                headers,
                // headers: currentHeaders,
            })
                .then((res) => {
                    if (res.status === 204) {
                        console.log('no change data for this service');
                        return;
                    }
                    return res.json();
                })
                .then((json) => {
                    if (!json) return;
                    setFetchedServiceData(json);
                    setIsCurrentArticle(json?.article_id);
                    setConnectedModal(true);
                    dispatch(setAccountId(account_id));
                    dispatch(setName(json?.name));
                    dispatch(setServiceData(json));
                    setIsModalActive(true);
                })
                .catch((err) => {
                    console.error(err);
                })
        }
    };

    const btns = [
        {
            btn: 'Редактировать',
            func: () => {
                onEditAccount(id)
            },
        },
    ];

    return (
        <div className='settings-row' key={key}>
            <div className='settings-row__name'>
                <img className='settings-row__icon' src={icon} />
                {name}
            </div>
            <div className='settings-row__tools'>

                {
                    isAdmin ?
                        <div className='settings-row__btns'>
                            <ButtonActionDrop
                                id={2}
                                btns={btns}
                            />

                            <ButtonBasic
                                grey
                                width='40px'
                                minWidth='40px'
                                text={<img src={minus_icon} />}
                                onClick={(e) => btnFunc()}
                            />
                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

export { SettingsRow }
