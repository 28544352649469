import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, Tabs, DropContainer, TableBox, RangeBox } from '@lk-gtcom/ecomlab-components';
import LineChart from '../../charts/LineChart';
import { fetchFilterMultonPerformance } from '../../common/filtersFunc';
import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useDataTable } from '../../common/hooks';
import { useChartData } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { OlegHttp, OlegHttps } from '../../fetchUrls';
import { addFiltersToUrlQuery } from '../../common/utils';
import { addFiltersByDataObj } from '../../common/utils';
import { getFiltersByUrlQuery } from '../../common/utils';
import { getDataObjByFilters } from '../../common/utils';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './ParsingAdvertisingPerfomance.scss'

const tabs = [
    {
        label: 'По ключевым словам',
        value: 'query'
    },
    {
        label: 'По брендам',
        value: 'brand'
    },
    {
        label: 'По категориям',
        value: 'category'
    },
    {
        label: 'По товарам',
        value: 'product'
    },
    {
        label: 'По адресам',
        value: 'address'
    },
    {
        label: 'По устройствам',
        value: 'device'
    }
]

const ParsingAdvertisingPerfomance = ({

}) => {
    const apiIdRef = useRef();
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const activeTab = useParams()['*'];

    // filters
    const [mpData, setMpData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [keywordsData, setKeywordsData] = useState([]);
    const [sizeData, setSizeData] = useState([]);
    const [colorData, setColorData] = useState([]);
    const [addressData, setAddressData] = useState([])
    const [deviceData, setDeviceData] = useState([])

    const [selectedMp, setSelectedMp] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedSize, setSelectedSize] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState([])
    const [selectedDevice, setSelectedDevice] = useState([])

    const [mpPage, setMpPage] = useState(1);
    const [categoryPage, setCategoryPage] = useState(1);
    const [brandPage, setBrandPage] = useState(1);
    const [keywordsPage, setKeywordsPage] = useState(1);
    const [sizePage, setSizePage] = useState(1);
    const [colorPage, setColorPage] = useState(1);
    const [addressPage, setAddressPage] = useState(1)
    const [devicePage, setDevicePage] = useState(1)

    const [mpQuery, setMpQuery] = useState('');
    const [categoryQuery, setCategoryQuery] = useState('');
    const [brandQuery, setBrandQuery] = useState('');
    const [keywordsQuery, setKeywordsQuery] = useState('');
    const [sizeQuery, setSizeQuery] = useState('');
    const [colorQuery, setColorQuery] = useState('');
    const [addressQuery, setAddressQuery] = useState('')
    const [deviceQuery, setDeviceQuery] = useState('')

    const [pageValue, setPageValue] = useState(tabs[0].value);

    // params
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultDate, setDefaultDate] = useState('');
    const [iniitalQuery, setInitialQuery] = useState(true);

    // charts
    const [showDropBox, setShowDropBox] = useState(false);
    const [tableMetrics, setTableMetrics] = useState([]);
    const [tableMetric, setTableMetric] = useState('');
    const [selectedItems, setSelectedItems] = useState([])

    const [setChartData, chartData, setChartFuncs] = useChartData();
    const { setChartLoading, resetChart } = setChartFuncs;

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const [date, setDate] = useState([
        new Date().getTime() - 604800000,
        new Date().getTime(),
    ]);
    const [dateFrom, dateTo] = date;

    useEffect(() => {
        setPageValue(activeTab);
    }, [activeTab]);

    //params
    useEffect(() => {
        if (iniitalQuery) {
            const dataObj = getFiltersByUrlQuery(searchParams);
            addFiltersByDataObj({
                dataObj,
                setSelectedCategory,
                setSelectedBrand,
                setSelectedMp,
                setSelectedColor,
                setSelectedSize,
                setSelectedDevice,
                setSelectedKeywords,
                setDate: setDefaultDate,
            });
            setInitialQuery(false);
        }
    }, [searchParams]);

    useEffect(() => {
        if (iniitalQuery) return;
        const dataObj = getDataObjByFilters({
            selectedCategory,
            selectedBrand,
            selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date
        });
        addFiltersToUrlQuery(dataObj, setSearchParams);
    }, [
        selectedCategory,
        selectedBrand,
        selectedMp,
        selectedColor,
        selectedAddress,
        selectedSize,
        selectedKeywords,
        selectedDevice,
        date
    ]);

    //логика для selected

    const mpParamRef = useRef([]);
    const categoryParamRef = useRef([]);
    const brandParamRef = useRef([]);
    const sizeParamRef = useRef([]);
    const addressParamRef = useRef([]);
    const colorParamRef = useRef([]);
    const deviceParamRef = useRef([]);
    const keywordsParamRef = useRef([]);


    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        mpParamRef.current = [params.get('mp_id')] || [];
        categoryParamRef.current = [params.get('category_id')] || [];
        brandParamRef.current = [params.get('brand_id')] || [];
        sizeParamRef.current = [params.get('size_id')] || [];
        addressParamRef.current = [params.get('address_id')] || [];
        colorParamRef.current = [params.get('color_id')] || [];
        deviceParamRef.current = [params.get('devicе_id')] || [];
        keywordsParamRef.current = [params.get('device_id')] || [];

        // if (mpParamRef.current.length > 0) {
        //   const mappedMarketplace = mpParamRef.current.map((mpId) =>
        //     options_prop.find((option) => String(option.value) === mpId)
        //   );
        //   setSelectedMarketplace(mappedMarketplace);
        // }
    }, [location.search]);

    // Площадка
    const mpFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: mpParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: mpPage,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            setSelectedFilter: setSelectedMp,
            set: setMpData,
            searchParam: mpQuery,
            filter: 'shop_name',
        });
    };

    useEffect(() => {
        setMpPage(1)
    }, [mpQuery, date]);

    useEffect(() => {
        mpFilter()
    }, [mpPage, mpQuery, date]);

    // Категория
    const categoryFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: categoryParamRef.current,
            page: categoryPage,
            selectedSpace: selectedSpace?.value,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            set: setCategoryData,
            setSelectedFilter: setSelectedCategory,
            searchParam: categoryQuery,
            filter: 'category_name',
        });
    };

    useEffect(() => {
        setCategoryPage(1)
    }, [categoryQuery, date]);

    useEffect(() => {
        categoryFilter()
    }, [categoryPage, categoryQuery, date]);

    // бренд
    const brandFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: brandParamRef.current,
            page: brandPage,
            selectedSpace: selectedSpace?.value,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            searchParam: brandQuery,
            setSelectedFilter: setSelectedBrand,
            set: setBrandData,
            filter: 'brand_name',
        });
    };

    useEffect(() => {
        setBrandPage(1)
    }, [brandQuery, date]);

    useEffect(() => {
        brandFilter()
    }, [brandPage, brandQuery, date]);

    // Место размещения
    const sizeTypeFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: sizeParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: sizePage,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            searchParam: sizeQuery,
            setSelectedFilter: setSelectedSize,
            set: setSizeData,
            filter: 'size_id',
        });
    };

    useEffect(() => {
        setSizePage(1)
    }, [sizeQuery, date]);

    useEffect(() => {
        sizeTypeFilter()
    }, [sizePage, sizeQuery, date]);

    // Тип размещения
    const colorFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: colorParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: colorPage,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            searchParam: colorQuery,
            setSelectedFilter: setSelectedColor,
            set: setColorData,
            filter: 'color_id',
        });
    };

    useEffect(() => {
        setColorPage(1)
    }, [colorQuery, date]);

    useEffect(() => {
        colorFilter()
    }, [colorPage, colorQuery, date]);

    // Ключевые слова
    const keywordsFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: keywordsParamRef.current,
            page: keywordsPage,
            selectedSpace: selectedSpace?.value,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            searchParam: keywordsQuery,
            setSelectedFilter: setSelectedKeywords,
            set: setKeywordsData,
            filter: 'query',
        });
    };

    useEffect(() => {
        setKeywordsPage(1)
    }, [keywordsQuery, date]);

    useEffect(() => {
        keywordsFilter()
    }, [keywordsPage, keywordsQuery, date]);


    // адрес
    const addressFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: addressParamRef.current,
            page: addressPage,
            selectedSpace: selectedSpace?.value,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            searchParam: addressQuery,
            setSelectedFilter: setSelectedAddress,
            set: setAddressData,
            filter: 'address',
        });
    };

    useEffect(() => {
        setAddressPage(1)
    }, [addressQuery, date]);

    useEffect(() => {
        addressFilter()
    }, [addressPage, addressQuery, date]);

    // Устройство
    const deviceFilter = () => {
        fetchFilterMultonPerformance({
            selected_id_list: deviceParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: devicePage,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedColor,
            selectedAddress,
            selectedSize,
            selectedKeywords,
            selectedDevice,
            date,
            searchParam: deviceQuery,
            setSelectedFilter: setSelectedDevice,
            set: setDeviceData,
            filter: 'device',
        });
    };

    useEffect(() => {
        setDevicePage(1)
    }, [deviceQuery, date]);

    useEffect(() => {
        deviceFilter()
    }, [devicePage, deviceQuery, date]);

    // table
    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filtersParam

        if (params) {
            [sort, filtersParam] = params
        }

        setLoading(true)

        const sort_params = sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/multon/performance/table/${pageValue}${sort_params}`
        } else {
            url = `${OlegHttp}/api/v1/multon/performance/table/${pageValue}${sort_params}`
        }

        let [date_from, date_to] = date;
        date_from = new Date(date_from + 86401000)
            ?.toISOString()
            ?.split('T')?.[0];
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

        let shop_name,
            brand_name,
            category_name,
            size_id,
            color_id,
            query,
            address,
            device

        if (selectedMp?.length > 0) {
            shop_name = selectedMp?.map((el) => el.value);
        } else {
            shop_name = ['all']
        }
        if (selectedBrand?.length > 0) {
            brand_name = selectedBrand?.map((el) => el.value);
        } else {
            brand_name = ['all']
        }
        if (selectedCategory?.length > 0) {
            category_name = selectedCategory?.map((el) => el.value);
        } else {
            category_name = ['all']
        }
        if (selectedSize?.length > 0) {
            size_id = selectedSize?.map((el) => el.value);
        } else {
            size_id = ['all']
        }
        if (selectedColor?.length > 0) {
            color_id = selectedColor?.map((el) => el.value);
        } else {
            color_id = ['all']
        }
        if (selectedKeywords?.length > 0) {
            query = selectedKeywords?.map((el) => el.value);
        } else {
            query = ['all']
        }
        if (selectedAddress?.length > 0) {
            address = selectedAddress?.map((el) => el.value);
        } else {
            address = ['all']
        }
        if (selectedDevice?.length > 0) {
            device = selectedDevice?.map((el) => el.value);
        } else {
            device = ['all']
        }

        const body = JSON.stringify({
            limit: paginatorRef.current.limit,
            page: paginatorRef.current.page,
            shop_name,
            brand_name,
            category_name,
            size_id,
            color_id,
            query,
            address,
            device,
            date_from,
            date_to,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setTable(json)
                setTableUrl(url)
                setTableBody(body)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })

    }

    // metrics
    const fetchChartMetrics = (abortController = new AbortController()) => {
        setTableMetrics([])
        if (pageValue) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${OlegHttps}/api/v1/multon/performance/graph/metrics`
            } else {
                url = `${OlegHttp}/api/v1/multon/performance/graph/metrics`
            }


            fetch(url, { method: 'GET', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setTableMetrics(json)
                    setTableMetric(json[0]?.value)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                })
        }
    }

    // chart
    const fetchChart = (abortController = new AbortController()) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/multon/performance/graph/${pageValue}/${tableMetric}`
        } else {
            url = `${OlegHttp}/api/v1/multon/performance/graph/${pageValue}/${tableMetric}`
        }

        let [date_from, date_to] = date;
        date_from = new Date(date_from + 86401000)
            ?.toISOString()
            ?.split('T')?.[0];
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

        let shop_name,
            brand_name,
            category_name,
            size_id,
            color_id,
            query,
            address,
            device

        if (selectedMp?.length > 0) {
            shop_name = selectedMp?.map((el) => el.value);
        } else {
            shop_name = ['all']
        }
        if (selectedBrand?.length > 0) {
            brand_name = selectedBrand?.map((el) => el.value);
        } else {
            brand_name = ['all']
        }
        if (selectedCategory?.length > 0) {
            category_name = selectedCategory?.map((el) => el.value);
        } else {
            category_name = ['all']
        }
        if (selectedSize?.length > 0) {
            size_id = selectedSize?.map((el) => el.value);
        } else {
            size_id = ['all']
        }
        if (selectedColor?.length > 0) {
            color_id = selectedColor?.map((el) => el.value);
        } else {
            color_id = ['all']
        }
        if (selectedKeywords?.length > 0) {
            query = selectedKeywords?.map((el) => el.value);
        } else {
            query = ['all']
        }
        if (selectedAddress?.length > 0) {
            address = selectedAddress?.map((el) => el.value);
        } else {
            address = ['all']
        }
        if (selectedDevice?.length > 0) {
            device = selectedDevice?.map((el) => el.value);
        } else {
            device = ['all']
        }

        const body = JSON.stringify({
            selected_id_list: selectedItems,
            shop_name,
            brand_name,
            category_name,
            size_id,
            color_id,
            query,
            address,
            device,
            date_from,
            date_to,
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setChartData(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
                if (abortController.signal.aborted) return
            })
    }

    const handleCheck = (e) => {
        let el_arr = e
        let items = []
        if (pageValue == 'query') {
            items = el_arr.map((item) => item?.['Ключевое слово'])
        }
        if (pageValue == 'brand') {
            items = el_arr.map((item) => item?.['Бренд'])
        }
        if (pageValue == 'category') {
            items = el_arr.map((item) => item?.['Категория'])
        }
        if (pageValue == 'product') {
            items = el_arr.map((item) => item?.['Товар'][4])
        }
        if (pageValue == 'address') {
            items = el_arr.map((item) => item?.['Адрес'])
        }
        if (pageValue == 'device') {
            items = el_arr.map((item) => item?.['Устройство'])
        }
        setSelectedItems(items)
    }

    useEffect(() => {
        resetChart()
    }, [pageValue]);

    useEffect(() => {
        if (selectedItems.length > 0) {
            fetchChart()
        }
    }, [
        pageValue,
        tableMetric,
        selectedItems,
        date
    ]);

    useEffect(() => {
        fetchChartMetrics()
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        fetchTableData([], abortController)
        return () => abortController.abort()
    }, [
        pageValue,
        date
    ]);

    return (
        <div className="parsing-advertising-by-keywords">
            <h1 class="parsing-advertising-by-keywords__title-main">Перфоманс</h1>

            <div className="parsing-advertising-by-keywords__toolbar-top">
                <FilterContainer
                    onReset={(e) => {
                        setSelectedMp([])
                        setSelectedCategory([]);
                        setSelectedBrand([]);
                        setSelectedColor([])
                        setSelectedSize([])
                        setSelectedKeywords([])
                        setSelectedAddress([])
                        setSelectedDevice([])
                    }}
                >

                    <DropDownSelector
                        options_prop={mpData}
                        state={selectedMp}
                        setState={(e) => setSelectedMp(e)}
                        className="connections_page_selector"
                        placeholder="Площадка"
                        fetchCallback={(e) => setMpPage(e)}
                        setInputChange={(e) => setMpQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={() => {
                            categoryFilter();
                            brandFilter();
                            addressFilter()
                            colorFilter()
                            sizeTypeFilter()
                            keywordsFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={(e) => setSelectedBrand(e)}
                        className="connections_page_selector"
                        placeholder="Бренд"
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={() => {
                            mpFilter()
                            categoryFilter();
                            addressFilter()
                            colorFilter()
                            sizeTypeFilter()
                            keywordsFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                        multi
                    />
                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={(e) => {
                            setSelectedCategory(e);
                        }}
                        className="connections_page_selector"
                        placeholder="Категория"
                        fetchCallback={(e) => {
                            setCategoryPage(e);
                        }}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                        onBlur={() => {
                            mpFilter()
                            brandFilter();
                            addressFilter()
                            colorFilter()
                            sizeTypeFilter()
                            keywordsFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                    />

                    <DropDownSelector
                        options_prop={sizeData}
                        state={selectedSize}
                        setState={(e) => setSelectedSize(e)}
                        // placeholder="Тип рекламы"
                        placeholder="Место размещения"
                        fetchCallback={(e) => setSizePage(e)}
                        setInputChange={(e) => setSizeQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            categoryFilter();
                            brandFilter();
                            addressFilter()
                            colorFilter()
                            keywordsFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={colorData}
                        state={selectedColor}
                        setState={(e) => setSelectedColor(e)}
                        // placeholder="Тип рекламы"
                        placeholder="Тип размещения"
                        fetchCallback={(e) => setColorPage(e)}
                        setInputChange={(e) => setColorQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            categoryFilter();
                            brandFilter();
                            addressFilter()
                            sizeTypeFilter()
                            keywordsFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={keywordsData}
                        state={selectedKeywords}
                        setState={(e) => setSelectedKeywords(e)}
                        // placeholder="Тип рекламы"
                        placeholder="Ключевое слово"
                        fetchCallback={(e) => setKeywordsPage(e)}
                        setInputChange={(e) => setKeywordsQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            categoryFilter();
                            brandFilter();
                            addressFilter()
                            colorFilter()
                            sizeTypeFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={addressData}
                        state={selectedAddress}
                        setState={(e) => setSelectedAddress(e)}
                        placeholder="Адрес"
                        fetchCallback={(e) => setAddressPage(e)}
                        setInputChange={(e) => setAddressQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            categoryFilter();
                            brandFilter();
                            colorFilter()
                            sizeTypeFilter()
                            keywordsFilter()
                            deviceFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={deviceData}
                        state={selectedDevice}
                        setState={(e) => setSelectedDevice(e)}
                        // placeholder="Тип баннера"
                        placeholder="Устройство"
                        fetchCallback={(e) => setDevicePage(e)}
                        setInputChange={(e) => setDeviceQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            categoryFilter();
                            brandFilter();
                            addressFilter()
                            colorFilter()
                            sizeTypeFilter()
                            keywordsFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                </FilterContainer>
                <RangeBox
                    defaultValue={defaultDate}
                    setDate={e => setDate(e)} />
            </div>

            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
                name={chartData?.title ? chartData?.title : ''}
            >
                <LineChart
                    selectedMetric={tableMetric}
                    setSelectedMetric={setTableMetric}
                    metricsList={tableMetrics}
                    {...chartData}
                />
            </DropContainer>

            <Tabs tabs={tabs} pageValue={pageValue} />

            <TableBox
                {...tableProps}
                paginator={true}
                ref={paginatorRef}
                fetchCallback={e => fetchTableData(e)}
                tableUrl={tableUrl}
                tableBody={tableBody}
                onCheck={e => handleCheck(e)}
                onUncheckAllDependence={[
                    pageValue,
                    selectedAddress,
                    selectedBrand,
                    selectedCategory,
                    selectedDevice,
                    selectedKeywords,
                    selectedMp,
                    selectedColor,
                    selectedSize
                ]}
                hideSorting={true}
            />
        </div>
    );
};

export { ParsingAdvertisingPerfomance };
