// import Feedback from './Feedback/Feedback'
import time_icon from './img/time_icon.svg'
import view_icon from './img/view_icon.svg'
import calendar_icon from './img/calendar_icon.svg'
// import ListOfContent from './ListOfContent/ListOfContent'
import { InputSearch, ArticleView, ShortTileArticleBox, usePagination, NoArticles } from "@lk-gtcom/ecomlab-components";
import { MoonLoader } from 'react-spinners'
// import { transliter } from '../../common/utils'
// import { createPortal } from 'react-dom'
// import { ShareComponent } from '../ShareComponent/ShareComponent'
// import { ModalArticleAddElement } from '../Modal/ModalArticleAddElement/ModalArticleAddElement'
import ListOfContent from '../ArticleTypesComponent/ListOfContent/ListOfContent';
import { useState, useEffect, useId } from 'react'
// import ArticleElement, { ARTICLE_TYPES } from './ArticleElement'
// import { ModalSelectionSocialMedia } from '../Modal/ModalSelectionSocialMedia/ModalSelectionSocialMedia'
import { TextareaAutosize } from '@mui/material'
import TitleContentEditable from '../../../components/Modal/ModalAddTask2/test/TitleContentEditable';
import { RavshanHttps } from '../../../fetchUrls';
import headers from '../../../common/headers';
import { useDebounce } from '../../../hooks/useDebounce';
import { SelectedBlocks } from '../ArticleTypesComponent/SelectedBlocks/SelectedBlocks';
import './ArticleViewV2.scss'

const ArticleViewV2 = ({
    loading,
    readonly = true,
    apiData,
    contentData,
    setContentData,
    setListOfContent,
    publishEventSource,
    portalId,
    setChanged,
    isNew,
    articleTitle,
    showTitle = true,
    setArticleTitle,
    articleTitleRef,
    setContentChanged,
    hideInfoPanel = false,
    hideRecommendation = false,
    contentLinks,
    articlePath,
    articleId,
    title,
    setTitle,
    description,
    setDescription,
    pathBread,
    wiki,
    hiddenSeo = false,
    modalStyle = false,
    parseText = false,
    listLinks,
    setListLinks,
    setBreadcrumbs
}) => {
    const [showAddBlockPopup, setShowAddBlockPopup] = useState(false)
    const [newElementId, setNewElementId] = useState(-1)
    const [newElementPostition, setNewElementPosition] = useState()
    const id = useId()
    const [changeId, setChangeId] = useState(id)
    const [activeLang, setActiveLang] = useState(() => localStorage.getItem('lang') ?? 'ru')
    const [isDropShare, setIsDropShare] = useState(false)
    const [isModalSocial, setIsModalSocial] = useState(false)
    const [isChangeTypeModal, setIsChangeTypeModal] = useState(false)

    const componentRefs = []

    const onSetTitle = (value) => {
        const url = `${RavshanHttps}/api/v2/article/set_name`
        const body = JSON.stringify({
            article_id: articleId,
            name: value
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {

            })
            .catch(err => {
                console.error(err);

            })
    }
    const debounceTitle = useDebounce(onSetTitle, 1000)

    const setSeoTitle = (value) => {
        const url = `${RavshanHttps}/api/v2/article/set_title`
        const body = JSON.stringify({
            article_id: articleId,
            title: value
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {

            })
            .catch(err => {
                console.error(err);

            })
    }
    const debounceSeoTitle = useDebounce(setSeoTitle, 1000)

    const setSeoDesctiption = (value) => {
        const url = `${RavshanHttps}/api/v2/article/set_description`
        const body = JSON.stringify({
            article_id: articleId,
            description: value
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {

            })
            .catch(err => {
                console.error(err);

            })
    }
    const debounceSeoDesctiption = useDebounce(setSeoDesctiption, 1000)

    const addContent = async (type) => {
        const url = `${RavshanHttps}/api/v2/article/add_content`
        const body = JSON.stringify({
            article_id: articleId,
            content: [{ type, value: '', place: newElementPostition }]
        })

        return await fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ block_id, block_type }) => {
                setNewElementId(block_id)
                return { id: block_id, type: block_type }
            })
            .catch(err => {
                console.error(err);

            })
    }

    const changeContent = async (type, id, data) => {
        const url = `${RavshanHttps}/api/v2/article/change_content`
        const body = JSON.stringify({
            article_id: articleId,
            content: [
                data
            ],
            block_id: id,
            block_type: type
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {

            })
            .catch(err => {
                console.error(err);

            })
    }

    const getSavedArray = () => {
        const result = []
        componentRefs.forEach(element => {
            result.push({
                type: element.type,
                data: element.getData(),
                id: element.id,
                align: element.getAlign(),
                fontSize: element.getSize()
            })
        })
        return result
    }

    const translateAuthor = () => {
        if (activeLang == 'ru') return 'Неизвестный автор'
        if (activeLang == 'tr') return 'Bilinmeyen Yazar'
        if (activeLang == 'en') return 'Unknown_author'
    }

    const translateMin = () => {
        if (activeLang == 'en') return 'min'
        if (activeLang == 'tr') return 'dak'
        return 'мин'
    }

    const translateNoViews = () => {
        if (activeLang == 'en') return '0'
        // 'Нет просмотров' 
        if (activeLang == 'tr') return '0'
        // 'Görüntüleme yok'
        return '-'
        // 'Нет просмотров'
    }

    const translateRecommendations = () => {
        if (activeLang == 'en') return 'This is going to be interesting'
        if (activeLang == 'tr') return ' Ilginiz çeker'
        return 'Будет интересно'
    }

    const applyRef = element => {
        if (element) componentRefs.push(element)
    }

    const onChildElementChange = () => {
        // computeContentList()
        setChangeId(prev => prev + 1)
        if (typeof setChanged === 'function') {
            setChanged(true)
        }
    }

    const noContent = contentData.length === 0

    const changeBlockType = async (type) => {

        const stateWithChanges = getSavedArray()

        const currentElem = stateWithChanges.filter(({ id }) => id === newElementId)
        const res = await changeContent({ currentElem, type })

        const stateCopy = stateWithChanges.map((el) => {
            if (el.id === newElementId) {
                return { ...el, type }
            } else {
                return el
            }
        })

        setContentData(stateCopy)
        if (typeof setChanged === 'function') {
            setChanged(true)
        }
        setIsChangeTypeModal(false)
    }

    const addNewBlock = async (type, customPosition = newElementPostition) => {
        // we have to collect changes from HTML here, otherwise all new blocks will be empty
        // console.log(type);

        const res = await addContent(type)

        const stateWithChanges = getSavedArray()
        const stateCopy = [
            ...stateWithChanges.slice(0, customPosition + 1),
            res,
            ...stateWithChanges.slice(customPosition + 1)
        ]


        setContentData(stateCopy)
        if (typeof setChanged === 'function') {
            setChanged(true)
        }
    }

    // const computeContentList = () => setListOfContent(componentRefs
    //     .filter(ref => ref?.type === ARTICLE_TYPES.header)
    //     .map(ref => ({ linkText: ref.getData(), path: `${ref.getId()}` })))

    const deleteBlock = (id, type) => {
        const url = `${RavshanHttps}/api/v2/article/delete_content`
        const body = JSON.stringify({
            article_id: articleId,
            block_id: id,
            block_type: type
        })

        if (contentData?.length === 1) {

            setContentData(prev => prev?.map(el => ({ ...el, data: '' })))
            return
        }

        fetch(url, { method: 'POST', body, headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {

                const stateWithChanges = getSavedArray()
                const stateCopy = [...stateWithChanges]
                setContentData(stateCopy.filter(el => el?.id !== id))
                if (typeof setChanged === 'function') {
                    setChanged(true)
                }
            })
            .catch(err => {
                console.error(err);

            })
    }

    // подписываемся на click event кнопки, по которой вызывается паблишинг, чтобы на момент срабатывания в родительском компоненте были свежие данные
    useEffect(() => {
        function updateContent() {
            setContentData(getSavedArray())
        }

        if (publishEventSource) {
            publishEventSource.addEventListener('click', updateContent)
        }

        return () => {
            if (publishEventSource) {
                publishEventSource.removeEventListener('click', updateContent)
            }
        }
    }, [changeId, contentData])

    // useEffect(() => {
    //     if (!readonly || isNew) {
    //         if (articleId !== -1) {
    //             if (Array.isArray(apiData?.content) & apiData?.content?.length === 0) {
    //                 addNewBlock(ARTICLE_TYPES.description)
    //                 return
    //                 // const addNewBlockTimer = setTimeout(() => addNewBlock(ARTICLE_TYPES.description, 0), 500)
    //                 // return () => clearTimeout(addNewBlockTimer)
    //             }
    //         }
    //     }

    //     const content = []
    //     const data = Array.isArray(apiData?.content) ? [...apiData?.content] : []
    //     for (const element of data?.sort((a, b) => a.place - b.place)) {
    //         const articleElement = { id: element?.id }
    //         content.push(articleElement)
    //         const keys = Object.keys(element)

    //         if (keys.find(name => name.startsWith('header'))) {
    //             articleElement.type = ARTICLE_TYPES.header
    //             articleElement.data = element.header_text
    //             articleElement.align = element.align
    //             articleElement.fontSize = element.fontSize
    //             continue
    //         }
    //         if (keys.find(name => name.startsWith('text'))) {
    //             articleElement.type = ARTICLE_TYPES.description
    //             articleElement.data = element.text
    //             articleElement.align = element.align
    //             articleElement.fontSize = element.fontSize
    //             continue
    //         }
    //         if (keys.find(name => name.startsWith('photo'))) {
    //             articleElement.type = ARTICLE_TYPES.image
    //             articleElement.data = { value: element.value, alt: element?.alt }
    //             continue
    //         }
    //         if (keys.find(name => name.startsWith('video'))) {
    //             articleElement.type = ARTICLE_TYPES.video
    //             articleElement.data = { value: element.value }
    //             continue
    //         }
    //         if (keys.find(name => name.startsWith('notification'))) {
    //             articleElement.type = ARTICLE_TYPES.warningBlock
    //             articleElement.data = element.notification_text
    //             articleElement.align = element.align
    //             articleElement.fontSize = element.fontSize
    //             continue
    //         }
    //         if (keys.find(name => name.startsWith('formBtn'))) {
    //             articleElement.type = ARTICLE_TYPES.formBtn
    //             articleElement.data = element.formBtn
    //             continue
    //         }
    //         if (keys.find(name => name.startsWith('social_networks'))) {
    //             articleElement.type = ARTICLE_TYPES.links
    //             articleElement.data = element.social_networks
    //             continue
    //         }
    //     }

    //     setContentData(content)
    // }, [apiData, articleId])

    // эффект для обновления содержания
    // useEffect(() => {
    //     if (!readonly || isNew) {
    //         if (articleId !== -1) {

    //             if ((Array.isArray(apiData?.content) || isNew) & contentData?.length === 0) {
    //                 addNewBlock(ARTICLE_TYPES.description, 0)
    //             }
    //         }
    //     }
    //     computeContentList()
    // }, [contentData])

    // Обработчик на клик вне элемента
    useEffect(() => {
        const isDrop = (e) => {
            let el = e.target
            if (!el.closest(`#share`)) {
                setIsDropShare(false)
            }
        }
        document.addEventListener('click', isDrop)

        return () => {
            document.addEventListener('click', isDrop)
        }
    }, [])

    // const contentComponents = contentData?.map((articleElement, index) => {
    //     return <ArticleElement
    //         isNew={isNew}
    //         key={articleElement?.id ? articleElement?.id : (articleElement.type + articleElement.data + index)}
    //         isFirstElement={index === 0}
    //         {...articleElement}
    //         setShowAddBlockPopup={setShowAddBlockPopup}
    //         setNewElementId={setNewElementId}
    //         newElementId={newElementId}
    //         setNewElementPosition={setNewElementPosition}
    //         addNewBlock={addNewBlock}
    //         position={index}
    //         articleId={articleId}
    //         editMode={!readonly}
    //         ref={applyRef}
    //         onChange={onChildElementChange}
    //         onDelete={deleteBlock}
    //         // changeBlockType={changeBlockType}
    //         setIsChangeTypeModal={setIsChangeTypeModal}
    //     />
    // })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    // При инициализации - запись крошек
    // useEffect(() => {
    //     const name = apiData?.name
    //     const id = apiData?.id
    //     if (setBreadcrumbs) {
    //         setBreadcrumbs((prev) => {
    //             console.log(prev)

    //         } )
    //     }
    // }, []);

    const user_name = localStorage.getItem('client_name')
    const user_email = localStorage.getItem('email')
    const curr_author = user_name ? user_name : user_email
    return (
        <div className='article-view'>

            {/* {isModalSocial && <ModalSelectionSocialMedia setIsModal={(e) => setIsModalSocial(e)} />} */}
            <section className={`article-view__content ${modalStyle ? 'article-view__content-modal' : ''}`}>
                <div className='article'>
                    {
                        loading
                            ?
                            <div className="article-loader" >
                                <MoonLoader
                                    color="#7b7b7b"
                                    speedMultiplier={0.5}
                                />
                            </div>
                            :
                            <>
                                {(!hideInfoPanel) && <div className='article-view__info-article'>

                                    {/*Дата создания*/}
                                    <div className='article-view__date-box'>
                                        {/* <img src={calendar_icon.src} /> */}
                                        <p className='article-view__text-grey'>
                                            {isNew ? new Date()?.toLocaleDateString('ru-ru') :
                                                (apiData?.date
                                                    ?
                                                    new Date(apiData?.date)?.toLocaleDateString('ru-ru')
                                                    :
                                                    'Неизвестная дата')}
                                        </p>
                                    </div>

                                    {/*Просмотры*/}
                                    <div className='article-view__view-box'>
                                        <img src={view_icon} />
                                        <p className='article-view__text-grey'>
                                            {apiData?.views ? apiData?.views : translateNoViews()}
                                        </p>
                                    </div>

                                    {/*Время чтения*/}
                                    <div className='article-view__time-box'>
                                        <img src={time_icon} />
                                        <p className='article-view__text-grey'>
                                            {`${apiData?.reading_time_min ? apiData?.reading_time_min : '0'} ${translateMin()}`}
                                        </p>
                                    </div>

                                    {/*Автор*/}
                                    <div className='article-view__share-and-author'>

                                        {isNew ?
                                            <p className='article-view__author-element'>
                                                <div className='article-view__author-avatar'
                                                    style={{ backgroundColor: '#F79E6C' }}
                                                >{curr_author?.split(' ')
                                                    .map(str => str[0])
                                                    .reduce((acc, current) => acc += current, '')?.toUpperCase() || '-'}
                                                </div>
                                                <p className='article-view__text-grey article-view__text-grey_author'>
                                                    {curr_author}
                                                </p>
                                            </p>
                                            :
                                            <p className='article-view__author-element'>
                                                <p className='article-view__text-grey article-view__text-grey_author'>
                                                    {apiData?.author?.[0] ? apiData?.author?.[0] : translateAuthor()}
                                                </p>
                                                <div className='article-view__author-avatar'
                                                    style={{
                                                        backgroundColor: (Array.isArray(apiData?.author) && apiData?.author?.length > 1)
                                                            ?
                                                            apiData?.author[1]
                                                            :
                                                            ''
                                                    }}

                                                >{(Array.isArray(apiData?.author) && apiData?.author?.length > 0) > 0 ?
                                                    apiData?.author[0]?.split(' ')
                                                        .map(str => str[0])
                                                        .reduce((acc, current) => acc += current, '')?.toUpperCase() : '-'}
                                                </div>
                                            </p>
                                        }

                                        {/* <ShareComponent
                                            hideText
                                            activeLang={activeLang}
                                            id={articleId}
                                            articlePath={articlePath}
                                        /> */}
                                    </div>
                                </div>

                                }

                                {(!isNew && readonly) &&
                                    showTitle ?
                                    <div className='title-box'>
                                        < h1 className='title__big'>{articleTitle || 'Нет названия заголовка'}</h1>
                                    </div>
                                    :
                                    null}

                                {(!readonly || isNew) &&
                                    < div className='title-box'>
                                        <TextareaAutosize
                                            type='text'
                                            ref={articleTitleRef}
                                            placeholder='Введите заголовок'
                                            className='title__big title-input'
                                            value={articleTitle}
                                            onChange={e => {
                                                debounceTitle(e.target.value)
                                                setContentChanged(true)
                                                setArticleTitle(e.target.value)
                                            }}
                                            autoFocus={true}
                                        />

                                        <div className='btn-group'>
                                            <button onClick={() => articleTitleRef.current.focus()} className='btn__edit-light'></button>
                                            <button onClick={() => setArticleTitle("")} className='btn__close-round'></button>
                                        </div>
                                    </div>
                                }

                                {(!hiddenSeo && (!readonly || isNew)) && <>
                                    <div className='article__input-group'>
                                        <TitleContentEditable
                                            titleStatic='Title'
                                            placeholder='Введите title для статьи (70 символов)'
                                            requared={true}
                                            title={title}
                                            setTitle={value => {
                                                debounceSeoTitle(value)
                                                if (typeof setTitle === 'function') {
                                                    setTitle(value)
                                                }
                                            }}
                                            maxlength={70}
                                        />

                                        <TitleContentEditable
                                            titleStatic='Description'
                                            requared={true}
                                            placeholder='Введите description для статьи (140 символов)'
                                            title={description}
                                            setTitle={(value) => {
                                                debounceSeoDesctiption(value)
                                                if (typeof setDescription === 'function') {
                                                    setDescription(value)
                                                }
                                            }}
                                            maxlength={140}
                                        />
                                    </div>
                                </>
                                }

                                <ListOfContent
                                    readonly
                                    links={listLinks}
                                    lang={activeLang}
                                />

                                <SelectedBlocks
                                    readonly={readonly}
                                    apiData={apiData}
                                    articleId={articleId}
                                    setListLinks={setListLinks}
                                />

                                {/* {contentComponents} */}
                            </>
                    }

                    {/* {
                        !readonly
                        &&
                        <div className='btn-box'>
                            {noContent && <button className='btn__round-blue' onClick={(e) => setShowAddBlockPopup(true)} />}
                        </div>
                    } */}

                    {/* <Feedback /> */}
                </div>
            </section >

            {/* {(readonly && !hideRecommendation) &&
                <div className='article-view__this-will-be-interesting'>
                    <h2 className='article-view__this-will-be-interesting-title'>{translateRecommendations()}</h2>
                    <div className='article-view__this-will-be-interesting-content'>
                        {
                            apiData?.preview?.map(el => {
                                return <ShortTileArticleBox
                                    key={el}
                                    {...el}
                                    id={el.id}
                                    name={el?.cpu}
                                    views={el?.clicks}
                                    timeToRead={el?.reading_time_min}
                                    photoPreview={el?.photo}
                                    date={new Date(el?.date)}
                                    activeLang={activeLang}
                                    fromArticle={true}
                                    pathBread={pathBread}
                                    wiki={wiki}
                                />
                            })
                        }
                    </div>
                </div>} */}
        </div >
    )
}

export default ArticleViewV2