import { useState } from 'react';
import not_image from './img/not-img.svg'
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import "./ImageSlider.scss"


const ImageSlider = ({ src }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % src.length);
    };

    const prevSlide = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + src.length) % src.length
        );
    };

    return (
        <div className='image-slider'>

            <div className='image-slider__img-box'>
                {src.length > 0 ?
                    <img src={src[currentIndex]} className='image-slider__img' alt='Баннер' />
                    :
                    <img className='image-slider__no-img' src={not_image} />
                }
            </div>
            {src.length > 1 && <div className='image-slider__group-btn'>
                <ButtonBasic
                    green
                    text={'<'}
                    onClick={prevSlide}
                    minWidth='40px'
                    width='40px'
                />
                <ButtonBasic
                    green
                    text={'>'}
                    onClick={nextSlide}
                    minWidth='40px'
                    width='40px'
                />
            </div>}
        </div>
    )
}

export { ImageSlider }