'use client'
import { useState } from 'react';
import customHeaders, { getSpace } from '../../common/headers';
import useGeneralStore from '../../store/general';
import active from './img/active.svg';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import './TariffPlan.scss';
import AddBalanceModal from '../AddBalanceModal';
import chevron_down from './img/bx-chevron-down.svg'
import info_circle from './img/bx-info-circle.svg'
import { TabsGreen } from '../../components/TabsGreen/TabsGreen';
import { useEffect } from 'react';
import book_open from './imgs/bx-book-open.svg'
import cart_alt from './imgs/bx-cart-alt.svg'
import chart from './imgs/bx-chart.svg'
import donate_heart from './imgs/bx-donate-heart.svg'
import face from './imgs/bx-face.svg'
import layout from './imgs/bx-layout.svg'
import message_detail from './imgs/bx-message-detail.svg'
import package_icon from './imgs/bx-package.svg'
import notification from './imgs/bx-notification.svg'
import slider from './imgs/bx-slider.svg'


const TariffPlan = ({ current }) => {
  const tarifs_cards_min_list = [
    {
      period: '1 Месяц',
      value: 1
    },
    {
      period: '3 месяца',
      discount: 20,
      value: 3
    },
    {
      period: '6 месяцев',
      discount: 20,
      value: 6
    },
    {
      period: '12 месяцев',
      discount: 20,
      value: 12
    },
  ];

  const tariffs_list = [
    {
      label: 'Бесплатно',
      sum: 0,
      color: '#FF7A00',
      description: `Попробуйте сервис бесплатно`,
      ref: 'free'
    },
    {
      label: 'Старт',
      sum: 4900,
      color: '#1410DD',
      description: `В самый раз на старте компании`,
      ref: 'start'
    },
    {
      label: 'Команда',
      sum: 12000,
      color: '#7700D4',
      description: `Если в команде не так много человек`,
      popular: true,
      ref: 'team'
    },
    {
      label: 'ПРО',
      sum: 19000,
      color: '#169D5C',
      description: `Получите полные возможности сервиса`,
      ref: 'pro'
    }
  ];

  const parametr_list = [
    {
      label: 'Раздел Аналитика',
      icon: face,
      inside: [
        {
          label: 'Продвинутая аналитика',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Аналитика рекламы',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Аналитика Акций',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Аналитика Заказов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'ABC+XYZ анализ',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Аналитика Цен',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Аналитика упущенных продаж по более чем 20 параметрам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Подсказочник на основе ИИ',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел Товары',
      icon: package_icon,
      inside: [
        {
          label: 'Перенос товаров на маркетплейсы',
          text1: <>
            <span style={{ color: '#00B45E)' }}>включено 100 переносов</span> <br />
            далее по 10 руб за перенос
          </>,
          text2: <>
            <span style={{ color: '#00B45E)' }}>включено 300 переносов</span> <br />
            далее по 8 руб за перенос
          </>,
          text3: <>
            <span style={{ color: '#00B45E)' }}>включено 500 переносов</span> <br />
            далее по 6 руб за перенос
          </>,
          text4: <>
            <span style={{ color: '#00B45E)' }}>включено 2000 переносов</span> <br />
            далее по 4 руб за перенос
          </>,
        },
        {
          label: 'Создание мастер товаров',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Обновление цен',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Обновление остатков',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Анализ Товара по 40+ метрикам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Улучшение товаров',
          text1: <>
            включено 100 улучшений
          </>,
          text2: <>
            включено 100 улучшений
          </>,
          text3: <>
            включено 100 улучшений
          </>,
          text4: <>
            включено 100 улучшений
          </>,
        },
        {
          label: 'Внесение и анализ себестоимости',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Расчет поставок',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел отзывы',
      icon: message_detail,
      inside: [
        {
          label: 'Генерация кастомных ответов на каждый отзыв с ИИ. Опционально - утверждение оператором',
          text1: <>
            <span style={{ color: '#00B45E)' }}>включено 100 отзывов</span> <br />
            далее по 9 руб за отзыв
          </>,
          text2: <>
            <span style={{ color: '#00B45E)' }}>включено 300 отзывов</span> <br />
            далее по 7 руб за отзыв
          </>,
          text3: <>
            <span style={{ color: '#00B45E)' }}>включено 500 отзывов</span> <br />
            далее по 5 руб за отзыв
          </>,
          text4: <>
            <span style={{ color: '#00B45E)' }}>включено 2000 отзывов</span> <br />
            далее по 3 руб за отзыв
          </>,
        },
        {
          label: 'Автоопределение темы отзыва',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Сортировка входящих отзывов с ИИ по 40 темам. Возможность задавать новые темы',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Персонализация ответов с шаблонными переменными – Имя, Маркетплейс, Бренд, Товар',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Еженедельные отчеты по изменению трендов в отзывах. Например, если у какого-то товара выросло количество негативных отзывов об упаковке',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Кастомизация ответов ИИ',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Настройка стиля ответов ИИ под ваш бренд',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Правила ответов для товаров и источников',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Настройки разных правил ответов под каждый товар, бренд или маркетплейс',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: '2 500+ готовых шаблонных ответов для любой темы отзыва и оценки',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Аналитика по отзывам и вопросам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел акции',
      icon: donate_heart,
      inside: [
        {
          label: 'Умная лента заказов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Все заказы в одном окне',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Настройка собственной дополнительной этикетки',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Анализ заказов по статусам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание собственных статусов заказов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },

      ]
    },
    {
      label: 'Раздел заказы',
      icon: cart_alt,
      inside: [
        {
          label: 'Умная лента заказов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Все заказы в одном окне',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Настройка собственной дополнительной этикетки',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Анализ заказов по статусам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание собственных статусов заказов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел маркетинг',
      icon: chart,
      inside: [
        {
          label: 'Аналитики рекламных кампаний по 20+ параметрам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Управление рекламными компаниями по всем площадкам',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание и корректирование рекламных компаний',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Просмотр товаров в рекламных компаниях',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание автоматических правил по созданию и добавлению товаров в рекламных компаниях',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Продвинутая система рекомендаций',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел задачи',
      icon: slider,
      inside: [
        {
          label: 'Создание задач и досок проектов',
          text1: <>
            <span style={{ color: '#00B45E)' }}>включено 10 проектов</span> <br />
            далее по 100 руб за проект
          </>,
          text2: <>
            <span style={{ color: '#00B45E)' }}>включено 20 проектов</span> <br />
            далее по 100 руб за проект
          </>,
          text3: <>
            <span style={{ color: '#00B45E)' }}>включено 40 проектов</span> <br />
            далее по 100 руб за проект
          </>,
          text4: <>
            <span style={{ color: '#00B45E)' }}>включено 100 проектов</span> <br />
            далее по 100 руб за проект
          </>,
        },
        {
          label: 'Добавление сотрудников в проект',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Анализ активности сотрудников',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел CRM',
      icon: layout,
      inside: [
        {
          label: 'Создание собственных таблиц и связей',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Уникальный конструктор таблиц',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание статусов и задач',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел WIKI',
      icon: book_open,
      inside: [
        {
          label: 'Создание своих статей',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание каталога статей',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Публикация статей на внешних ресурсах',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Раздел чат-бот',
      icon: chart,
      inside: [
        {
          label: 'Загрузка файлов для чат бота',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Создание чат бота',
          text1: <>
            <span style={{ color: '#00B45E)' }}>включено 10 чат ботов</span> <br />
            далее по 1000 за чат бота
          </>,
          text2: <>
            <span style={{ color: '#00B45E)' }}>включено 20 чат ботов</span> <br />
            далее по 1000 за чат бота
          </>,
          text3: <>
            <span style={{ color: '#00B45E)' }}>включено 40 чат ботов</span> <br />
            далее по 1000 за чат бота
          </>,
          text4: <>
            <span style={{ color: '#00B45E)' }}>включено 100 чат ботов</span> <br />
            далее по 1000 за чат бота
          </>,
        },
        {
          label: 'Автоматизация общения с пользователями с помощью ИИ',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Шаблоны чат ботов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
    {
      label: 'Управление',
      icon: notification,
      inside: [
        {
          label: 'Создание подключений к площадкам',
          text1: <>включено 3 площадки</>,
          text2: <>включено 5 площадок</>,
          text3: <>включено 6 площадок</>,
          text4: <>включено 10 площадок</>,
        },
        {
          label: 'Добавление сотрудников',
          text1: <>включено 10 сотрудников</>,
          text2: <>включено 20 сотрудников</>,
          text3: <>включено 40 сотрудников</>,
          text4: <>включено 100 сотрудников</>,
        },
        {
          label: 'Создание Пространств для разделения проектов',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
        {
          label: 'Управление уведомлениями',
          base1: 'active',
          base2: 'active',
          base3: 'active',
          base4: 'active',
        },
      ]
    },
  ];

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [currentTariff, setCurrentTariff] = useState('3 месяца');
  const [isModalBalance, setIsModalBalance] = useState(false);
  const [modelsTariff, setIsModelsTariff] = useState([])
  const [tariffInSpace, setTariffInSpace] = useState([])

  const [isOpenItems, setIsOpenItems] = useState(new Set([current ? current : '']));

  const btns = [
    {
      label: '1 Месяц',
      value: 1
    },
    {
      label: <>3 месяца <div className="discount">5 %</div></>,
      value: 3
    },
    {
      label: <>6 месяцев <div className="discount">5 %</div></>,
      value: 6
    },
    {
      label: <>12 месяцев <div className="discount">5 %</div></>,
      value: 12
    },
  ]
  const [status, setStatus] = useState(btns?.[0]?.value)
  const [currentPlan, setCurrentPlan] = useState()

  // Получение моделей тарифов
  const fetchGetModelsTariff = (period) => {
    const url = `https://apps0.ecomru.ru:4453/api/v1/tariff/models?portal_id=1&period=${period}`;

    fetch(url, { method: 'GET', headers, })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setIsModelsTariff(json)
      })
      .catch((err) => console.error(err))
      .finally(() => { });
  };

  // Активация модели тарифа
  const fetchActivateModelsTariff = (id) => {
    const url = `https://apps0.ecomru.ru:4453/api/v1/tariff/activate`

    const body = JSON.stringify({
      id: id
    })

    fetch(url, { method: 'POST', body, headers })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        console.log(json)
      })
      .catch(err => {
        console.error(err);
      })
  }

  // Получение тарифов пространства
  const fetchGetModelsTariffInSpace = (id) => {
    const url = `https://apps0.ecomru.ru:4453/api/v1/tariff/all`

    fetch(url, { method: 'GET', headers, })
      .then(async res => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then(json => {
        setTariffInSpace(json)
      })
      .catch(err => {
        console.error(err);
      })
  }

  useEffect(() => {
    fetchGetModelsTariffInSpace()
  }, []);

  useEffect(() => {
    fetchGetModelsTariff(status)
  }, [status]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window?.location?.search);
    const pricePlan = queryParams.get('ref-pricePlan');
    if (pricePlan) {
      setCurrentPlan(pricePlan)
    }
    localStorage.removeItem('ref-pricePlan')
    localStorage.removeItem('ref-page')
  }, [window?.location?.search]);


  return (
    <>
      <div className="tariffs-plan">
        {isModalBalance && (
          <AddBalanceModal setIsModalActive={() => setIsModalBalance(false)} />
        )}
        <div className="tariffs-plan__header">
          <h1 className="tariffs-plan__title">Тарифы</h1>
          <div className="tariffs-plan__my-plan">
            Ваш тариф |
            {tariffInSpace?.map(({ tariff_id }, ind) =>
              <span>{tariff_id + (tariffInSpace.length - 1 !== ind ? ', ' : '')}</span>
            )}
          </div>
          {/* <ButtonBasic
            green
            width="190px"
            text={
              <div className="content-btn">
                <img src={add_icon} alt="icon" />
                Пополнить баланс
              </div>
            }
            onClick={() => setIsModalBalance(true)}
          /> */}
        </div>

        <div className="tariffs-plan__top">
          <div className="cards-group">

            <TabsGreen
              btns={btns}
              currentBtn={status}
              setCurrentBtn={(e) => setStatus(e)}
              checkValue={true}
            />

            <div className='tariffs-plan__cards'>
              {modelsTariff?.map(({ id, name, portal_name, period, description, price, currency, created_at, ref }, ind) => (
                <div className="tariff-card"
                // style={{ border: ref == currentPlan ? '3px solid #00B45E' : '' }}
                >
                  {/* {popular && <div className='tariff-card__sticker'>{portal_name}</div>} */}
                  <h3 className="tariff-card__name"
                  // style={{ color: color }}
                  >{portal_name}</h3>
                  <div className="tariff-card__sum-box"> {price?.toLocaleString()} <span>{` ${currency}`}</span>{'/мес'}</div>
                  <p className="tariff-card__description">{description}</p>
                  <ButtonBasic
                    green
                    text="Попробовать"
                    width="100%"
                    size="36px"
                    style={{ marginTop: '7px' }}
                    onClick={(e) => fetchActivateModelsTariff(id)}
                  />
                </div>
              ))}
            </div>

            <div className='tariffs-plan__content'>
              {parametr_list.map(({ label, icon, inside }) => {
                const parrent_label = label
                return (
                  <div className='tariffs-plan__item'>
                    <div className='tariffs-plan__item-min' style={{
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        const newState = new Set(isOpenItems);
                        if (newState.has(label)) {
                          newState.delete(label);
                        } else {
                          newState.add(label);
                        }
                        setIsOpenItems(newState);
                      }}
                    >
                      <p className="tariffs-plan__label"><img src={icon} />{label}</p>
                      <button className={isOpenItems.has(parrent_label) ? 'tariffs-plan__drop' : 'tariffs-plan__drop_hide'}>
                        <img src={chevron_down} />
                      </button>
                    </div>

                    <div>
                      {inside?.length > 0 && <div className={isOpenItems.has(parrent_label) ? 'tariffs-plan__items' : 'tariffs-plan__items_hide'}>
                        {inside?.map(({ label, base1, base2, base3, base4, text1, text2, text3, text4 }) => {
                          return <div className={'tariffs-plan__item-min'}>
                            <div className='tariffs-plan__item-section-label'>
                              <p className="tariffs-plan__label-min">{label} <img src={info_circle} /></p>
                            </div>
                            <div className='tariffs-plan__item-section'>
                              {base1 && base1 == 'active' ? <img className="tariffs-plan__done" src={active} /> : ''}
                              {text1 && <p className='tariffs-plan__text'>{text1}</p>}
                            </div>
                            <div className='tariffs-plan__item-section'>
                              {base2 && base2 == 'active' ? <img className="tariffs-plan__done" src={active} /> : ''}
                              {text2 && <p className='tariffs-plan__text'>{text2}</p>}
                            </div>
                            <div className='tariffs-plan__item-section'>
                              {base3 && base3 == 'active' ? <img className="tariffs-plan__done" src={active} /> : ''}
                              {text3 && <p className='tariffs-plan__text'>{text3}</p>}
                            </div>
                            <div className='tariffs-plan__item-section'>
                              {base4 && base4 == 'active' ? <imfix styles g className="tariffs-plan__done" src={active} /> : ''}
                              {text4 && <p className='tariffs-plan__text'>{text4}</p>}
                            </div>
                          </div>
                        })}
                      </div>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TariffPlan };
