import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { ModalSelectionSocialMedia } from './ModalSelectionSocialMedia/ModalSelectionSocialMedia'
import { EditWrapper } from '../EditWrapper/EditWrapper';
import { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce';
import { Link } from 'react-router-dom'
import dzen from './img/dzen.svg'
import facebook from './img/facebook.svg'
import insta from './img/insta.svg'
import linkendIn from './img/linkendIn.svg'
import ok from './img/ok.svg'
import pikabu from './img/pikabu.svg'
import telegram from './img/telegram.svg'
// import vcru from './img/vcru.svg'
import vk from './img/vk.svg'
import wp from './img/wp.svg'
import x from './img/x.svg'
import "./SocialMedia.scss"

const list_media = {
    'facebook': [facebook, '#0863F7'],
    'dzen.ru': [dzen, '#000'],
    'instagram.com': [insta, 'conic-gradient(from 133deg at 50.22% 50%, #F5854C 131.40000343322754deg, #6256CB 194.40000772476196deg, #9A39B9 298.7999939918518deg, #D8317A 360deg)'],
    'linkedin.com': [linkendIn, '#0077B5'],
    // 'vc.ru': [vcru, '#F6E4E8'],
    'ok.ru': [ok, '#FF7600'],
    'pikabu.ru': [pikabu, '#88C657'],
    't.me': [telegram, '#3390EC'],
    'vk.com': [vk, '#07F'],
    'whatsapp.com': [wp, '#27C200'],
    'x.com': [x, '#020203']
}


const SocialMedia = forwardRef(({
    data,
    editMode,
    onChange,
    id,
    setContentArr,
    setCurrentIndex,
    setIsModal,
    ind,
    deleteBlock,
    changeContent,
    readonly = false
}, ref) => {

    const [isModalSocial, setIsModalSocial] = useState(false)
    const [inputValue, setInputValue] = useState(data[0]?.properties ? data[0]?.properties : {})
    const [listSocial, setListSocial] = useState([])

    console.log(inputValue)

    // Удаление блока
    const handleDeleteBlock = (e) => {
        //  setContent(e.currentTarget.textContent)
        setContentArr((prev) => {
            return prev.filter((el) => {
                if (el?.block_id != id) {
                    return el
                }
            })
        })
        deleteBlock(id)
    }

    const getIcon = (path) => {
        let current;
        for (let key in list_media) {
            if (path.includes(key)) {
                current = list_media[key]
            }
        }
        if (current) {
            return current
        }
    }

    useEffect(() => {
        // if (Array.isArray(data)) {
        //     if (data?.length > 0) {
        //         let obj = {}
        //         data[0]?.properties?.forEach((el => {
        //             if (el) {
        //                 const { name, value } = el
        //                 obj[name] = value
        //             }
        //         }))
        //         setInputValue(obj)
        //         setListSocial(data)
        //     }
        // }
    }, [data])

    useEffect(() => {
        const keys = Object.keys(inputValue)
        if (keys?.length > 0) {
            setListSocial(keys?.map(name => ({ [name]: inputValue?.[name] })))
        }
    }, [inputValue]);

    useImperativeHandle(ref, () => ({
        // прикрутить открытие системного диалога с выбором изображения
        focus: () => { },
        data: inputValue
    }), [inputValue])

    const list_link = () => {
        const list = []

        for (const el in inputValue) {
            list.push({ name: el, value: inputValue[el] })
        }
        return list
    }

    const debounceChange = useDebounce(changeContent, 1000)

    const handleInput = (e) => {
        //  setContent(e.currentTarget.textContent)
        debounceChange(id,
            [
                {
                    value: '',
                    properties: inputValue
                }
            ]
        )
        setContentArr((prev) => {
            return prev.map((el) => {
                if (el?.block_id == id) {
                    return {
                        ...el,
                        content: [
                            {
                                value: '',
                                properties: inputValue
                            }
                        ]
                    }
                } else {
                    return el
                }
            })
        })
    }

    useEffect(() => {
        handleInput()
    }, [inputValue]);

    return (
        <EditWrapper
            readonly={readonly}
            deleteFunc={(e) => handleDeleteBlock(id)}
            type='social'
            child={
                <>
                    {
                        <div className='social-media-links'
                            onContextMenu={(e) => {
                                e.preventDefault()
                                setCurrentIndex(ind)
                                setIsModal(true)
                            }}
                        >
                            {editMode && <ButtonBasic
                                grey
                                text={'Добавить ссылку'}
                                onClick={(e) => setIsModalSocial(true)}
                            />}

                            {isModalSocial && <ModalSelectionSocialMedia
                                inputValue={inputValue}
                                setInputValue={(e) => setInputValue(e)}
                                setIsModal={(e) => setIsModalSocial(e)
                                } />}

                            <div className='social-media-links__links'>
                                {list_link()?.map(({ value, name, icon }) => {
                                    if (value) {
                                        return <Link to={value} target='_blank'
                                            className='social-media-links__item-bkg'
                                            style={{
                                                background: getIcon(value)?.length > 0 && getIcon(value)[1]
                                            }}
                                            key={value}>
                                            <div className='social-media-links__item'>
                                                {
                                                    <img className='article__link-icon' alt='icon' src={getIcon(value)?.length > 0 && getIcon(value)[0]} />
                                                }
                                                <h3 className='social-media-links__link-name'>Мы в {name?.charAt(0)?.toUpperCase() + name?.slice(1)}</h3>
                                            </div>
                                        </Link>
                                    }
                                })}
                            </div>
                        </div>

                    }
                </>
            }
        />
    )
})

export { SocialMedia }