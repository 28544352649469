import right_arrow_alt from './img/bx-right-arrow-alt.svg'
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { useState, useEffect } from 'react';
import { BordCard } from '../BordCard/BordCard';
import { ModalCreateBoard } from '../ModalCreateBoard/ModalCreateBoard';
import customHeaders, { getSpace } from '../../../common/headers';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import useGeneralStore from '../../../store/general';
import './BordTask.scss'

const list_cars = [
    {
        name: 'Доска для задач 1',
        sum_task: 33,
        date: '13 окт. - 17 отк.'
    },
    {
        name: 'Доска для длинное название',
        sum_task: 2,
        date: '13 окт. - 17 отк.'
    },
    {
        name: 'Доска для длинное название',
        sum_task: 2,
    },
    {
        name: 'Доска для длинное название',
        sum_task: 2,
    }
]


const BordTask = ({
    ref,
    setIsOpen,
    isOpen,
    taskId,
    setCurrentBordTask
}) => {
    // const location = useLocation()
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [isModal, setIsModal] = useState(false)
    const [bords, setBords] = useState([])
    const [currentBord, setCurrentBord] = useState(null)
    const [isEdit, setIsEdit] = useState(false)

    // Получение досок
    const fetchGetBord = () => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v3/tasks/board/task_boards`;
        } else {
            url = `${RavshanHttp}/api/v3/tasks/board/task_boards`;
        }


        const body = JSON.stringify({
            task_id: taskId
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => res.json())
            .then((json) => {
                setBords(json);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        if (taskId) {
            fetchGetBord()
        }
    }, [taskId]);

    useEffect(() => {
        setCurrentBordTask(currentBord)
    }, [currentBord]);

    // useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const paramValue = queryParams.get('bord_id');
    //     const current = bords.filter((el) => paramValue == el.id)
    //     setCurrentBord(current?.[0] || bords?.[0])
    // }, [bords]);


    return (
        <>
            {isModal && <ModalCreateBoard
                fetchGetBord={fetchGetBord}
                currentBord={currentBord}
                setIsModal={setIsModal}
                taskId={taskId}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
            />}

            <div className={isOpen ? 'bord-task_open' : 'bord-task'} ref={ref}>
                <div className='bord-task__btn-group'>
                    <button className='bord-task__btn'
                        onClick={(e) => setIsOpen(!isOpen)}
                    >
                        <img src={right_arrow_alt}
                            style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
                        />
                    </button>
                    <ButtonBasic
                        green
                        width='166px'
                        minWidth='166px'
                        text='Добавить'
                        size='26px'
                        style={{
                            minHeight: '26px',
                            borderRadius: '4px'
                        }}
                        onClick={(e) => setIsModal(true)}
                    />
                </div>

                <div className={isOpen ? 'bord-task__content_open' : 'bord-task__content'}>
                    {
                        bords?.map(({ name, sum_task, date, id, start_time, end_time }) => {
                            return <BordCard
                                id={id}
                                name={name}
                                sum_task={sum_task}
                                date={date}
                                start_time={start_time}
                                end_time={end_time}
                                fetchGetBord={fetchGetBord}
                                setIsModal={(e) => setIsModal(e)}
                                setCurrentBord={(e) => setCurrentBord(e)}
                                currentBord={currentBord}
                                setIsEdit={setIsEdit}
                            />
                        })}
                </div>

                <div className={isOpen ? 'bord-task__content-btns' : 'bord-task__content-btns_open'}>
                    <button className='bord-task__btn'
                        onClick={(e) => setIsOpen(!isOpen)}
                    >12</button>

                    <button className='bord-task__btn'
                        onClick={(e) => setIsOpen(!isOpen)}
                    >ДН</button>
                </div>
            </div>
        </>

    )
}

export { BordTask }