import React, { forwardRef, useId } from 'react'
import { useState, useEffect, useRef } from 'react'
import { TextArticle } from '../TextArticle/TextArticle'
import { TitleText } from '../TitleText/TitleText'
import { SocialMedia } from '../SocialMedia/SocialMedia'
import { InformationBlock } from '../InformationBlock/InformationBlock'
import { ModalReplaceBlock } from '../ModalReplaceBlock/ModalReplaceBlock'
import { ImgArticle } from '../ImgArticle/ImgArticle'
import { FormBtn } from '../FormBtn/FormBtn'
import { VideoArticle } from '../VideoArticle/VideoArticle'
import customHeaders, { getSpace } from '../../../../common/headers'
import useGeneralStore from '../../../../store/general'
import "./SelectedBlocks.scss"

const SelectedBlocks = forwardRef(({
    apiData,
    articleId,
    setListLinks,
    readonly,
    data
}, ref) => {
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [content, setContent] = useState([])
    const [isModal, setIsModal] = useState()
    const [currentIndex, setCurrentIndex] = useState(null)
    const [beforeBlock, setBeforeBlock] = useState(null)

    // Первоначальный рендер
    // записываем контент если есть, если нет - создаем 1 новый блок
    useEffect(() => {
        if (apiData?.blocks?.length > 0 || readonly) {
            const blocks = apiData?.blocks
            setContent(blocks)
        } else {
            if (articleId && (articleId !== -1) && (content?.length === 0)) {
                fetchCreateBlock('text', null)
            }
        }
    }, [apiData, articleId]);

    // Создание блока, в ответ получаем id созданного
    const fetchCreateBlock = (type, before = null) => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/create_block`;
        } else {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/create_block`;
        }

        const body = JSON.stringify({
            type: type ? type : 'text',
            before: before,
            article_id: articleId
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => res.json())
            .then((json) => {
                const init_data = {
                    block_type: 'text',
                    block_before: before,
                    block_id: json,
                    content: [{
                        value: ''
                    }]
                }
                if (content.length > 0) {

                    const index = content.findIndex(item => item.block_id === before);

                    const newArr = [
                        ...content.slice(0, currentIndex + 1), // Все элементы до индекса
                        init_data, // Вставляем новый элемент
                        ...content.slice(currentIndex + 1) // Все элементы после индекса
                    ];
                    setContent(newArr)

                    //  let content_init = content
                    //  console.log(content_init)
                    //  let current_content = content_init.splice(0, 0, init_data);
                    //  console.log(current_content)
                    //setContent((prev) => [...prev, init_data])
                } else {
                    setContent([init_data])
                }

            })
            .catch((err) => console.error(err))
    };

    // Изменение контента блока
    const changeContent = (id, content) => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/article_content`;
        } else {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/article_content`;
        }

        const body = JSON.stringify({
            block_id: id,
            content: content
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
            })
            .catch((err) => console.error(err))
    }

    // Изменение блока
    const changeBlock = (type, article_id, block_id, before) => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/change_block`;
        } else {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/change_block`;
        }

        const body = JSON.stringify({
            type: type,
            before: before,
            article_id: article_id,
            block_id: block_id
        });

        fetch(url, { body, method: 'PUT', headers })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
            })
            .catch((err) => console.error(err))
    }

    // Удаление блока
    const deleteBlock = (delete_id) => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/delete_block?block_id=${delete_id}`;
        } else {
            url = `https://apps0.ecomru.ru:4447/api/v2/article/delete_block?block_id=${delete_id}`;
        }

        fetch(url, { method: 'DELETE', headers })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
            })
            .catch((err) => console.error(err))
    }

    // Добавление блока
    const addNewBlock = () => {
        if (articleId && articleId !== -1) {
            fetchCreateBlock('text', beforeBlock)
        }
    }

    useEffect(() => {
        const links = content?.filter((el) => el.block_type === 'title')
            .map((el) => {
                return { title: el?.content?.[0]?.value, id: el.block_id }
            })
            if(setListLinks) {
                setListLinks(links)
            }
    }, [content]);

    return (
        <>
            {isModal && <ModalReplaceBlock
                currentIndex={currentIndex}
                setIsModal={setIsModal}
                setContent={setContent}
                changeBlock={changeBlock}
                articleId={articleId}
            />}

            <div
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        console.log(e.key)
                        e.preventDefault(e.key)
                        addNewBlock()
                    }
                }}
            >
                {
                    content?.map(({ block_type, content, block_id, block_before }, ind) => {
                        const commonProps = {
                            id: block_id,
                            key: block_id, // Используем уникальный блок ID для ключа
                            ind,
                            setIsModal,
                            setCurrentIndex,
                            editMode: true,
                            data: content,
                            changeContent,
                            before: block_id,
                            setBeforeBlock,
                            setContentArr: setContent,
                            deleteBlock,
                            readonly
                        };

                        if (block_type == 'text') return <TextArticle
                            {...commonProps}
                        />

                        if (block_type == 'title') return <TitleText
                            {...commonProps}
                        />

                        if (block_type == 'social') return <SocialMedia
                            {...commonProps}
                            editMode={true}
                        />

                        if (block_type == 'info') return <InformationBlock
                            {...commonProps}
                            editMode={true}
                        />

                        if (block_type == 'image') return <ImgArticle
                            {...commonProps}
                            editMode={true}
                        />

                        if (block_type == 'form') return <FormBtn
                            {...commonProps}
                            editMode={true} />

                        if (block_type == 'video') return <VideoArticle
                            {...commonProps}
                            editMode={true} />
                    })
                }
            </div>
        </>
    )
})

export { SelectedBlocks }