import customHeaders, { getSpace } from '../../common/headers';
import { TableBox } from '@lk-gtcom/ecomlab-components';
import { STATUSES } from '../Article/Article';
import { MoonLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom';
import { RavshanArticlesHttps } from '../../fetchUrls';
import { handleApiResponseOrThrow } from '../../common/utils';
import { useState, useEffect, useRef } from 'react';
import {
  usePagination,
  ArticleMinBox,
  TooltipYellow,
  DropDownSelector,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './ArticleMain.scss';

const ArticleMain = ({
  selectedEndLevelValue,
  selectedPortalValue,
  activeLang,
  pathBread,
  selectView,
  setTags,
  setSelectedTags,
  selectedTags,
  setTotalParent,
  sortingRef,
  selectedState,
  paths,
  setIsArticle,
  setSelectedEndLevelValue,
  fetchGetBreadcrumbs
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const path = useParams()['*'];
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState({ data: [], label: {} });

  const [waiting, setWaiting] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const paginatorRef = useRef();
  const paginatorCallback = useRef();
  const tableFetchCallback = useRef();
  // const sortingRef = useRef()
  const tagsRef = useRef();

  const isBaseRoute = paths?.length === 0;

  const tableHeaders = articles?.labels;
  const tableData = articles?.data;

  const [columnSize, setColumnSize] = useState([]);
  const [sort, setSort] = useState({});

  const articleIdIndex = tableHeaders?.header.findIndex((h) => h === 'id');

  const [
    page,
    limit,
    total,
    setTotal,
    Paginator,
    resetPaginator,
    paginatorTransition,
  ] = usePagination(
    paginatorCallback.current,
    [],
    paginatorRef.current?.params
  );




  const checkArticleStatus = (articleId) => {
    const _headers = {
      ...headers,
      'article-id': articleId,
      Accept: 'application/json',
    };
    return fetch(`${RavshanArticlesHttps}/get_article_status`, {
      headers: _headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .catch(err => console.error(err))
  };

  const onRedirectCheck = (data, redirect) => {
    let id = data;
    if (Array.isArray(data)) {
      id = data[articleIdIndex];
    }
    checkArticleStatus(id).then((status) => {
      if (status === STATUSES.active) redirect();
      if (status === STATUSES.redact) {
        addNotification('Данная статья сейчас редактируется');
        return false;
      }
      if (status === STATUSES.update) {
        addNotification('Данная статья сейчас обновляется');
        return false;
      }
    });
  };

  const addNotification = (text) => {
    const id = Math.random();

    const removeNotification = () => {
      setNotifications((prev) => {
        const index = prev.findIndex((n) => n.id === id);
        if (index === -1) {
          return prev;
        }
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
    };

    setNotifications((prev) => [
      ...prev,
      {
        id: id,
        component: (
          <TooltipYellow
            key={id}
            text={text}
            setShowTooltip={removeNotification}
          />
        ),
      },
    ]);
    setTimeout(() => removeNotification(), 3000);
  };

  const saveWidthColumn = () => {
    if (selectView == 'Table') {
      const colWidth = [...document.querySelectorAll('.col-th-border')];
      if (colWidth.length > 0) {
        const arrWidth = colWidth.map((el) =>
          el.style.width ? el.style.width : '247px'
        );
        sessionStorage.setItem('col-width', arrWidth);
      }
    }
  };

  const fetchArticlesByLevel = (value, page, limit, params) => {
    saveWidthColumn();
    const abortController = new AbortController();
    // setArticles({ data: [], label: {} });
    setLoading(true);
    const url = `${RavshanArticlesHttps}/get_articles_by_parents`;

    let filters = null,
      sorting = null;
    if (params?.length) {
      [sorting, filters] = params;
    }
    if (sorting) setSort(sorting);

    const orderParam =
      selectView === 'Table'
        ? sorting?.length
          ? sorting[0]
          : ''
        : sortingRef.current
          ? sortingRef.current.param
          : '';
    const orderParamDirection =
      selectView === 'Table'
        ? sorting?.length
          ? sorting[1]
          : ''
        : sortingRef.current
          ? sortingRef.current.direction
          : '';

    const getHeaders = {
      ...headers,
      'level-id': value,
      language: activeLang?.toLowerCase(),
      page: page,
      limit: limit ?? 10,
      active: true,
      status: selectedState?.value,
    };

    if (orderParam)
      Object.assign(getHeaders, { 'order-param': btoa(encodeURI(orderParam)) });
    if (orderParamDirection)
      Object.assign(getHeaders, {
        'order-param-direction': orderParamDirection,
      });
    if (filters)
      Object.assign(getHeaders, {
        filters: btoa(encodeURI(JSON.stringify({ filters }))),
      });

    fetch(url, { headers: getHeaders, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then((json) => {

        if (!Object.entries(json).length) return;
        setArticles(json);
        localStorage.setItem('articles', JSON.stringify(json))
        setTotal(json.total);
        setTotalParent(json.total);
        setColumnSize(json.column_size);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
    // return abortController;
  };


  const fetchAllPortalsArticles = (value, page, limit, params) => {
    saveWidthColumn();
    const abortController = new AbortController();
    // setArticles({ data: [], label: {} });
    setLoading(true);
    const url = `${RavshanArticlesHttps}/get_articles_by_parents`;

    let filters = null,
      sorting = null;
    if (params?.length) {
      [sorting, filters] = params;
    }
    if (sorting) setSort(sorting);

    const orderParam =
      selectView === 'Table'
        ? sorting?.length
          ? sorting[0]
          : ''
        : sortingRef.current
          ? sortingRef.current.param
          : '';
    const orderParamDirection =
      selectView === 'Table'
        ? sorting?.length
          ? sorting[1]
          : ''
        : sortingRef.current
          ? sortingRef.current.direction
          : '';

    const getHeaders = {
      ...headers,
      'level-id': value,
      language: activeLang?.toLowerCase(),
      page: page,
      limit: limit ?? 10,
      active: true,
      status: selectedState?.value,
    };

    if (orderParam)
      Object.assign(getHeaders, { 'order-param': btoa(encodeURI(orderParam)) });
    if (orderParamDirection)
      Object.assign(getHeaders, {
        'order-param-direction': orderParamDirection,
      });
    if (filters)
      Object.assign(getHeaders, {
        filters: btoa(encodeURI(JSON.stringify({ filters }))),
      });

    fetch(url, { headers: getHeaders, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          const err = await res.json()
          throw new Error(JSON.stringify(err))
        }
      })
      .then((json) => {
        if (!Object.entries(json).length) return;
        setArticles(json);
        localStorage.setItem('articles', JSON.stringify(json))

        setTotal(json.total);
        setTotalParent(json.total);
        setColumnSize(json.column_size);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
    // return abortController;
  };

  const setArrayResponseToFilterData = (data, set) => {
    const idIndex = data.label?.header.findIndex((h) => h === 'id');
    const nameIndex = data.label?.header.findIndex((h) => h === 'name');
    set(data.data?.map((el) => ({ label: el[nameIndex], value: el[idIndex] })));
  };

  const fetchAllTags = () => {
    // fetch(`${RavshanArticlesHttps}/get_all_tag`, { headers })
    //   .then((response) => handleApiResponseOrThrow(response))
    //   .then((json) => setArrayResponseToFilterData(json, setTags))
    //   .catch((error) => console.error(error));
  };

  const fetchArticlesByTags = (page, limit, params) => {
    // saveWidthColumn();
    // setLoading(true);
    // // setArticles({ data: [], label: {} });

    // const url = `${RavshanArticlesHttps}/get_articles_by_tag`;

    // let filters = null,
    //   sorting = null;
    // if (params?.length) {
    //   [sorting, filters] = params;
    // }
    // if (sorting) setSort(sorting);

    // const orderParam =
    //   selectView === 'Table'
    //     ? sorting?.length
    //       ? sorting[0]
    //       : ''
    //     : sortingRef.current
    //     ? sortingRef.current.param
    //     : '';
    // const orderParamDirection =
    //   selectView === 'Table'
    //     ? sorting?.length
    //       ? sorting[1]
    //       : ''
    //     : sortingRef.current
    //     ? sortingRef.current.direction
    //     : '';

    // const getHeaders = {
    //   ...headers,
    //   language: activeLang?.toLowerCase(),
    //   tags: JSON.stringify(selectedTags),
    //   page: page,
    //   limit: limit ?? 10,
    //   active: false,
    //   status: selectedState?.value,
    // };

    // if (orderParam)
    //   Object.assign(getHeaders, { 'order-param': btoa(encodeURI(orderParam)) });
    // if (orderParamDirection)
    //   Object.assign(getHeaders, {
    //     'order-param-direction': orderParamDirection,
    //   });
    // if (filters)
    //   Object.assign(getHeaders, {
    //     filters: btoa(encodeURI(JSON.stringify({ filters }))),
    //   });

    // fetch(url, { headers: getHeaders })
    //   .then((response) => handleApiResponseOrThrow(response))
    //   .then((json) => {
    //     console.log('353', json);

    //     if (!Object.entries(json).length) return;
    //     setTotal(json.total);
    //     setTotalParent(json.total);
    //     setArticles(json);
    //     setColumnSize(json.column_size);
    //   })
    //   .catch((error) => console.error(error))
    //   .finally(() => setLoading(false));
  };

  useEffect(() => {
    setInitialRender(false);
    fetchAllTags([]);
  }, []);

  useEffect(() => {
    if (selectedTags?.length) {
      paginatorCallback.current = (filters) =>
        fetchArticlesByTags(page, limit, filters);
      tableFetchCallback.current = (filters) =>
        fetchArticlesByTags(page, limit, filters);
      fetchArticlesByTags(1, limit, paginatorRef.current?.params);
    }
    if (
      isBaseRoute &&
      selectedTags?.length === 0 &&
      tagsRef.current !== selectedTags &&
      !initialRender
    ) {
      paginatorCallback.current = (filters) =>
        fetchAllPortalsArticles(null, page, limit, filters);
      tableFetchCallback.current = (filters) =>
        fetchAllPortalsArticles(null, page, limit, filters);
      fetchAllPortalsArticles(null, 1, limit, paginatorRef.current?.params);
    }
    tagsRef.current = selectedTags;
  }, [selectedTags?.length, selectView, page, limit]);

  useEffect(() => {
    if (selectedPortalValue) {
      paginatorCallback.current = (filters) =>
        fetchAllPortalsArticles(selectedPortalValue, page, limit, filters);
      // tableFetchCallback.current = (filters) => fetchAllPortalsArticles(selectedPortalValue, page, limit, filters)
      fetchAllPortalsArticles(selectedPortalValue, page, limit);
      fetchGetBreadcrumbs(selectedPortalValue)
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (waiting) setWaiting(false);
    if (isBaseRoute || paginatorTransition) return;
    if (selectedPortalValue) {
      paginatorCallback.current = (filters) =>
        fetchAllPortalsArticles(selectedPortalValue, page, limit, filters);
      // tableFetchCallback.current = (filters) => fetchAllPortalsArticles(selectedPortalValue, page, limit, filters)
      fetchAllPortalsArticles(selectedPortalValue, page, limit);
      fetchGetBreadcrumbs(selectedPortalValue)
      window.scrollTo(0, 0);
    } else if (selectedEndLevelValue) {
      paginatorCallback.current = (filters) =>
        fetchArticlesByLevel(selectedEndLevelValue, page, limit, filters);
      fetchGetBreadcrumbs(selectedEndLevelValue)
      tableFetchCallback.current = (filters) =>
        fetchArticlesByLevel(selectedEndLevelValue, page, limit, filters);
      fetchArticlesByLevel(
        selectedEndLevelValue,
        page,
        limit,
        paginatorRef.current?.params
      );
      fetchGetBreadcrumbs(selectedEndLevelValue)
    }
    // оба уровня не могут быть равны нулю если адрес страницы отличается от дефолтного, поэтому ждём пока прогрузится сайдбар
    else {
      setTimeout(setWaiting(true), 1000);
      return;
    }
  }, [
    path,
    waiting,
    selectedEndLevelValue,
    selectedPortalValue,
    paginatorTransition,
    activeLang,
    selectView,
    page,
    limit,
    selectedState,
  ]);

  useEffect(() => {
    if (isBaseRoute) {
      paginatorCallback.current = (filters) =>
        fetchAllPortalsArticles(null, page, limit, filters);
      // tableFetchCallback.current = (filters) => fetchAllPortalsArticles(null, page, limit, filters)
      fetchAllPortalsArticles(null, 1, limit, paginatorRef.current?.params);
      setSelectedTags([]);
    }
    resetPaginator();
  }, [path, activeLang, selectView, selectedState]);

  useEffect(() => {
    if (typeof paginatorCallback.current === 'function' && !initialRender) {
      paginatorCallback.current(page, limit);
    }
  }, [sortingRef.current]);

  return (
    <div className="article-main">
      <div className="notifications-portal">
        {notifications.map((n) => n.component)}
      </div>

      <div className="content-and-filter">
        {
          <>
            {selectView === 'Table' ? (
              <div className="table-box">
                <TableBox
                  ref={paginatorRef}
                  fetchedData={tableData}
                  headers={tableHeaders}
                  columnSize={columnSize}
                  fetchCallback={(e) => {
                    resetPaginator();
                    tableFetchCallback.current(e);
                  }}
                  sort={sort}
                  loading={loading}
                  onRedirect={onRedirectCheck}
                // paginator={true}
                />
                {Paginator}
              </div>
            ) : loading ? (
              <div className="loader-box">
                <MoonLoader
                  className="loader"
                  color="#7b7b7b"
                  speedMultiplier={0.5}
                  size={65}
                />
              </div>
            ) : (
              <ArticleMinBox
                pathBread={pathBread}
                articlesData={articles}
                selectView={selectView}
                paginator={Paginator}
                onRedirect={onRedirectCheck}
                showStatus={true}
                activeLang={activeLang}
                wiki={true}
              />
            )}
          </>
        }
      </div>
    </div>
  );
};

export { ArticleMain };
