import home_icon from './img/home_icon.svg'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./Breadcrumb.scss"
// import { transliter } from '../../common/utils';

const translate = {
    news: {
        ru: 'новостей',
        en: 'news'
    }
}

const Breadcrumb = ({
    breadcrumbs,
    activeLang = 'ru',
    mainPath = '/wiki/articles/main',
    onPortalClick,
    setSelectedItemId
}) => {
    const navigate = useNavigate()
    const translateMain = () => {
        if (activeLang == 'en') return 'Main'
        if (activeLang == 'tr') return 'Ana Sayfa'
        return 'Главная'
    }

    return (
        <div className='header-breadcrumb'>
            <nav className="breadcrumb__nav">
                <ul className="breadcrumb">
                    {breadcrumbs.length > 0 ?

                        breadcrumbs.map(({ label, value, web_name }, ind) => {
                            return <li className="breadcrumb__link"
                                key={label + value + web_name + ind}
                            // onClick={e => onClickHandler('main', mainPath)}
                            >
                                {ind === 0 && <img className='img-home' src={home_icon} />}
                                {((ind + 1) === breadcrumbs?.length) ?
                                    <p
                                    >{label}</p>
                                    :
                                    <Link
                                        onClick={(e) => {
                                            onPortalClick(value)
                                            setSelectedItemId(value)
                                        }}
                                        to={ind == 0 ? mainPath : `/wiki/${value}/`}
                                    >
                                        {(ind === 0) ? translateMain() : label}
                                    </Link>}
                            </li>
                        })
                        :
                        <li className="breadcrumb__link">
                            <img className='img-home' src={home_icon} />
                            <p>
                                {translateMain()}
                            </p>
                        </li>

                    }
                </ul>
            </nav>
        </div>

    )
}

export default Breadcrumb