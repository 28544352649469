import customHeaders, { getSpace } from '../../common/headers'
import arrow_right from './img/arrow_right.svg'
import { RavshanHttps, RavshanHttp } from '../../fetchUrls'
import { user_light_grey } from '../../common/svg_img'
import { useEffect, useState } from 'react'
import useGeneralStore from '../../store/general'
import { AuthorAvatar } from '../../pages/ArticleComponents/AuthorAvatar/AuthorAvatar'
import './NotificationListItem.scss'

const NotificationListItem = ({ ...props }) => {
    const {
        id,
        author,
        notification_text,
        status_read,
        name,
        created_at,
        activeChat,
        setActiveChat,
        fetchGetNotificationsByPortal,
        readed
    } = props
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [statusRead, setStatusRead] = useState(status_read)

    const fetchChangeStatusRead = async (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/lk/change_read_status`
        } else {
            url = `${RavshanHttp}/api/lk/change_read_status`
        }



        const body = JSON.stringify({
            id: id,
            status: true
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {

            })
            .catch(err => console.error(err))
    }

    const fetchNotificationsCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/get_count_unread_msg`
        } else {
            url = `${RavshanHttp}/get_count_unread_msg`
        }

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(({ count }) => {
                // setNotifCount(count)
            })
            .catch(err => console.error(err))
    }

    return (
        < li className={activeChat?.id == id ? "messages-list__item_active" : "messages-list__item"}
            onClick={async (e) => {
                setActiveChat({ id })
                if (!statusRead) {
                    await fetchChangeStatusRead(id)
                    setStatusRead(true)
                    setTimeout(() => {
                        fetchGetNotificationsByPortal()
                        fetchNotificationsCount()
                    }, 100)
                }
            }}
        >
            <AuthorAvatar
                author={author?.[0] ? author : []}
            />

            <div className='messages-list__message-box'>
                <div className='messages-list__title-and-date'>
                    <p className='text'>{name?.[0] ? name?.[0] : 'Неизвестное название'}</p>

                    <div className='messages-list__info-message'>
                        <h5 className='messages-list__text'>{notification_text ? notification_text : 'Неизвестный текст'}</h5>
                        <p className='messages-list__date'>{created_at}</p>
                    </div>
                </div>
            </div>
            {readed ?
                <div className='read-message'>
                    <img src={arrow_right} />
                </div>
                :
                <span className='new-message'></span>}
        </li>
    )
}

export default NotificationListItem