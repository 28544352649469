import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./ModalReplaceBlock.scss"

const btns = [
    {
        label: 'Текст',
        type: 'text'
    },
    {
        label: 'Заголовок',
        type: 'title'
    },
    {
        label: 'Ссылки на социальные сети',
        type: 'social'
    },
    {
        label: 'Информационный блок',
        type: 'info'
    },
    {
        label: 'Изображение',
        type: 'image'
    },
    {
        label: 'Форма',
        type: 'form'
    },
    {
        label: 'Видео',
        type: 'video'
    },
]

const ModalReplaceBlock = ({
    setIsModal,
    currentIndex,
    setContent,
    changeBlock,
    articleId
}) => {

    // Замена блока
    const replaceBlock = (type, content) => {
        setContent((prev) => {
            const new_blocks_list = prev?.map((block, ind) => {
                if (currentIndex == ind) {
                    changeBlock(type, articleId, block?.block_id, block?.block_before)

                    return {
                        block_type: type,
                        block_id: block?.block_id,
                        block_before: block?.block_before,
                        content: content ? content : [
                            {
                                value: '',
                                properties: {}
                            }
                        ]
                    }
                } else {
                    return block
                }
            })

            return new_blocks_list
        })
        setIsModal(false)
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-replace-block'>
                {btns.map(({ label, type }) => {
                    return <button key={label + type} className='modal-replace-block__btn'
                        onClick={(e) => replaceBlock(type)}
                    >{label}</button>
                })}
            </div >
        </div>
    )
}

export { ModalReplaceBlock }