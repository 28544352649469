import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, Tabs, DropContainer, TableBox, RangeBox } from '@lk-gtcom/ecomlab-components';
import LineChart from '../../charts/LineChart';
import { fetchFilterMultonKeyword } from '../../common/filtersFunc';
import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useDataTable } from '../../common/hooks';
import { useChartData } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { OlegHttp, OlegHttps } from '../../fetchUrls';
import { addFiltersToUrlQuery } from '../../common/utils';
import { addFiltersByDataObj } from '../../common/utils'
import { getFiltersByUrlQuery } from '../../common/utils';
import { getDataObjByFilters } from '../../common/utils';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './ParsingAdvertisingByKeywords.scss'
import { TabsGreen } from '../../components/TabsGreen/TabsGreen';

const mp_options = [
    {
        label: 'Ozon',
        value: 1,
    },
    {
        label: 'Wildberries',
        value: 3,
    },
    {
        label: 'Yandex',
        value: 2,
    },
];

const btn_tab = [
    {
        label: 'Цена',
        value: 'true',
    },
    {
        label: 'Позиция',
        value: 'false',
    },
];

const tabs = [
    {
        label: 'По ключевым словам',
        value: 'query'
    },
    {
        label: 'По брендам',
        value: 'brand'
    },
    {
        label: 'По категориям',
        value: 'category'
    },
    {
        label: 'По товарам',
        value: 'product'
    },
    {
        label: 'По адресам',
        value: 'address'
    },
    {
        label: 'По устройствам',
        value: 'device'
    }
]

const ParsingAdvertisingByKeywords = ({

}) => {
    const apiIdRef = useRef();
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const activeTab = useParams()['*'];
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [advCampaignData, setAdvCampaignData] = useState([]);
    const [pageValue, setPageValue] = useState(tabs[0].value);
    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);

    // filters
    const [mpData, setMpData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [keywordsData, setKeywordsData] = useState([])
    const [productData, setProductData] = useState([])
    const [addressData, setAddressData] = useState([])
    const [deviceData, setDeviceData] = useState([])

    const [selectedMp, setSelectedMp] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedAddress, setSelectedAddress] = useState([])
    const [selectedDevice, setSelectedDevice] = useState([])

    const [mpPage, setMpPage] = useState(1);
    const [categoryPage, setCategoryPage] = useState(1);
    const [brandPage, setBrandPage] = useState(1);
    const [keywordsPage, setKeywordsPage] = useState(1)
    const [productPage, setProductPage] = useState(1)
    const [addressPage, setAddressPage] = useState(1)
    const [devicePage, setDevicePage] = useState(1)

    const [mpQuery, setMpQuery] = useState('');
    const [categoryQuery, setCategoryQuery] = useState('');
    const [brandQuery, setBrandQuery] = useState('');
    const [keywordsQuery, setKeywordsQuery] = useState('')
    const [productQuery, setProductQuery] = useState('')
    const [addressQuery, setAddressQuery] = useState('')
    const [deviceQuery, setDeviceQuery] = useState('')

    // params
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultDate, setDefaultDate] = useState('');
    const [iniitalQuery, setInitialQuery] = useState(true);

    // charts
    const [showDropBox, setShowDropBox] = useState(false);
    const [tableMetrics, setTableMetrics] = useState([]);
    const [tableMetric, setTableMetric] = useState('');
    const [selectedItems, setSelectedItems] = useState([])

    const [setChartData, chartData, setChartFuncs] = useChartData();
    const { setChartLoading, resetChart } = setChartFuncs;

    const [currentTab, setCurrentTab] = useState(btn_tab?.[0]?.value)

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const [date, setDate] = useState([
        new Date().getTime() - 604800000,
        new Date().getTime(),
    ]);

    //params
    useEffect(() => {
        if (iniitalQuery) {
            const dataObj = getFiltersByUrlQuery(searchParams);
            addFiltersByDataObj({
                dataObj,
                setSelectedCategory,
                setSelectedBrand,
                setSelectedMp,
                setSelectedKeywords,
                setSelectedAddress,
                setSelectedProduct,
                setSelectedDevice,
                setDate: setDefaultDate,
            });
            setInitialQuery(false);
        }
    }, [searchParams]);

    useEffect(() => {
        if (iniitalQuery) return;
        const dataObj = getDataObjByFilters({
            selectedCategory,
            selectedBrand,
            selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            date
        });
        addFiltersToUrlQuery(dataObj, setSearchParams);
    }, [
        selectedCategory,
        selectedBrand,
        selectedMp,
        selectedKeywords,
        selectedAddress,
        selectedProduct,
        selectedDevice,
        date
    ]);

    //логика для selected

    const mpParamRef = useRef([]);
    const categoryParamRef = useRef([]);
    const brandParamRef = useRef([]);
    const keywordsParamRef = useRef([]);
    const addressParamRef = useRef([]);
    const productParamRef = useRef([]);
    const deviceParamRef = useRef([]);


    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        mpParamRef.current = [params.get('mp_id')] || [];
        categoryParamRef.current = [params.get('category_id')] || [];
        brandParamRef.current = [params.get('brand_id')] || [];
        keywordsParamRef.current = [params.get('keywords_id')] || [];
        addressParamRef.current = [params.get('address_id')] || [];
        productParamRef.current = [params.get('product_id')] || [];
        deviceParamRef.current = [params.get('device_id')] || [];

        // if (mpParamRef.current.length > 0) {
        //   const mappedMarketplace = mpParamRef.current.map((mpId) =>
        //     options_prop.find((option) => String(option.value) === mpId)
        //   );
        //   setSelectedMarketplace(mappedMarketplace);
        // }
    }, [location.search]);

    useEffect(() => {
        setPageValue(activeTab);
    }, [activeTab]);

    // Площадка
    const mpFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: mpParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: mpPage,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            date,
            setSelectedFilter: setSelectedMp,
            set: setMpData,
            searchParam: mpQuery,
            filter: 'shop_name',
        });
    };

    useEffect(() => {
        setMpPage(1)
    }, [mpQuery, date]);

    useEffect(() => {
        mpFilter()
    }, [mpPage, mpQuery, date]);

    // Категория
    const categoryFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: categoryParamRef.current,
            page: categoryPage,
            selectedSpace: selectedSpace?.value,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            date,
            set: setCategoryData,
            setSelectedFilter: setSelectedCategory,
            searchParam: categoryQuery,
            filter: 'category_name',
        });
    };

    useEffect(() => {
        setCategoryPage(1)
    }, [categoryQuery, date]);

    useEffect(() => {
        categoryFilter();
    }, [categoryPage, categoryQuery, date]);

    // бренд
    const brandFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: brandParamRef.current,
            page: brandPage,
            selectedSpace: selectedSpace?.value,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            date,
            searchParam: brandQuery,
            setSelectedFilter: setSelectedBrand,
            set: setBrandData,
            filter: 'brand_name',
        });
    };

    useEffect(() => {
        setBrandPage(1)
    }, [brandQuery, date]);

    useEffect(() => {
        brandFilter();
    }, [brandPage, brandQuery, date]);

    // ключевые слова
    const keywordsFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: keywordsParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: keywordsPage,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            date,
            searchParam: keywordsQuery,
            setSelectedFilter: setSelectedKeywords,
            set: setKeywordsData,
            filter: 'query',
        });
    };

    useEffect(() => {
        setKeywordsPage(1)
    }, [keywordsQuery, date]);

    useEffect(() => {
        keywordsFilter();
    }, [keywordsPage, keywordsQuery, date]);

    // товары
    const productFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: productParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: productPage,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            date,
            searchParam: productQuery,
            setSelectedFilter: setSelectedProduct,
            set: setProductData,
            filter: 'product_name',
        });
    };

    useEffect(() => {
        setProductPage(1)
    }, [productQuery, date]);

    useEffect(() => {
        productFilter();
    }, [productPage, productQuery, date]);

    // адрес
    const addressFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: addressParamRef.current,
            page: addressPage,
            selectedSpace: selectedSpace?.value,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            searchParam: addressQuery,
            date,
            setSelectedFilter: setSelectedAddress,
            set: setAddressData,
            filter: 'address',
        });
    };

    useEffect(() => {
        setAddressPage(1)
    }, [addressQuery, date]);

    useEffect(() => {
        addressFilter();
    }, [addressPage, addressQuery, date]);

    // Устройства
    const deviceFilter = () => {
        fetchFilterMultonKeyword({
            selected_id_list: deviceParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: devicePage,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedKeywords,
            selectedAddress,
            selectedProduct,
            selectedDevice,
            searchParam: deviceQuery,
            date,
            setSelectedFilter: setSelectedDevice,
            set: setDeviceData,
            filter: 'device',
        });
    };

    useEffect(() => {
        setDevicePage(1)
    }, [deviceQuery, date]);

    useEffect(() => {
        deviceFilter();
    }, [devicePage, deviceQuery, date]);

    // table
    const fetchTableData = (params, abortController = new AbortController()) => {
        setLoading(true)
        let sort
        let filtersParam

        if (params) {
            [sort, filtersParam] = params
        }

        const avg_price = `?avg_price=${currentTab}`
        const sort_params = sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/multon/query/table/${pageValue}${avg_price}${sort_params}`
        } else {
            url = `${OlegHttp}/api/v1/multon/query/table/${pageValue}${avg_price}${sort_params}`
        }

        let [date_from, date_to] = date;
        date_from = new Date(date_from + 86401000)
            ?.toISOString()
            ?.split('T')?.[0];
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

        let shop_name,
            brand_name,
            category_name,
            query,
            product_name,
            address,
            device

        if (selectedMp?.length > 0) {
            shop_name = selectedMp?.map((el) => el.value);
        }
        if (selectedBrand?.length > 0) {
            brand_name = selectedBrand?.map((el) => el.value);
        }
        if (selectedCategory?.length > 0) {
            category_name = selectedCategory?.map((el) => el.value);
        }
        if (selectedKeywords?.length > 0) {
            query = selectedKeywords?.map((el) => el.value);
        }
        if (selectedProduct?.length > 0) {
            product_name = selectedProduct?.map((el) => el.value);
        }
        if (selectedAddress?.length > 0) {
            address = selectedAddress?.map((el) => el.value);
        }
        if (selectedDevice?.length > 0) {
            device = selectedDevice?.map((el) => el.value);
        }

        const body = JSON.stringify({
            limit: paginatorRef.current.limit,
            page: paginatorRef.current.page,
            shop_name,
            brand_name,
            query,
            product_name,
            address,
            device,
            category_name,
            date_from,
            date_to,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setTable(json)
                setTableUrl(url)
                setTableBody(body)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    // metrics
    const fetchChartMetrics = (abortController = new AbortController()) => {
        setTableMetrics([])
        if (pageValue) {

            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${OlegHttps}/api/v1/multon/query/graph/metrics`
            } else {
                url = `${OlegHttp}/api/v1/multon/query/graph/metrics`
            }


            fetch(url, { method: 'GET', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    setTableMetrics(json)
                    setTableMetric(json[0]?.value)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                })
        }
    }

    // chart
    const fetchChart = (abortController = new AbortController()) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/multon/query/graph/${pageValue}/${tableMetric}`
        } else {
            url = `${OlegHttp}/api/v1/multon/query/graph/${pageValue}/${tableMetric}`
        }

        let [date_from, date_to] = date;
        date_from = new Date(date_from + 86401000)
            ?.toISOString()
            ?.split('T')?.[0];
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

        let shop_name,
            brand_name,
            category_name,
            query,
            product_name,
            address,
            device

        if (selectedMp?.length > 0) {
            shop_name = selectedMp?.map((el) => el.value);
        }
        if (selectedBrand?.length > 0) {
            brand_name = selectedBrand?.map((el) => el.value);
        }
        if (selectedCategory?.length > 0) {
            category_name = selectedCategory?.map((el) => el.value);
        }
        if (selectedKeywords?.length > 0) {
            query = selectedKeywords?.map((el) => el.value);
        }
        if (selectedProduct?.length > 0) {
            product_name = selectedProduct?.map((el) => el.value);
        }
        if (selectedAddress?.length > 0) {
            address = selectedAddress?.map((el) => el.value);
        }
        if (selectedDevice?.length > 0) {
            device = selectedDevice?.map((el) => el.value);
        }

        const body = JSON.stringify({
            selected_id_list: selectedItems,
            shop_name,
            brand_name,
            query,
            product_name,
            address,
            device,
            category_name,
            date_from,
            date_to,
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setChartData(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
            })
    }

    const handleCheck = (e) => {
        let el_arr = e
        let items = []
        console.log(e)
        if (pageValue == 'query') {
            items = el_arr.map((item) => item?.['Ключевое слово'])
        }
        if (pageValue == 'brand') {
            items = el_arr.map((item) => item?.['Бренд'])
        }
        if (pageValue == 'category') {
            items = el_arr.map((item) => item?.['Категория'])
        }
        if (pageValue == 'product') {
            items = el_arr.map((item) => item?.['Товар'][1])
        }
        if (pageValue == 'address') {
            items = el_arr.map((item) => item?.['Адрес'])
        }
        if (pageValue == 'device') {
            items = el_arr.map((item) => item?.['Устройство'])
        }
        setSelectedItems(items)
    }

    useEffect(() => {
        fetchChartMetrics()
    }, []);

    useEffect(() => {
        deviceFilter();
    }, [devicePage]);

    useEffect(() => {
        const abortController = new AbortController();
        fetchTableData([], abortController)
        return () => abortController.abort()
    }, [
        pageValue,
        date,
        currentTab
    ]);

    useEffect(() => {
        resetChart()
    }, [
        pageValue,
        currentTab
    ]);

    useEffect(() => {
        if (selectedItems.length > 0) {
            fetchChart()
        }
    }, [
        pageValue,
        tableMetric,
        selectedItems,
        date
    ]);

    return (
        <div className="parsing-advertising-by-keywords">
            <h1 class="parsing-advertising-by-keywords__title-main">Парсинг рекламы: по ключевым словам</h1>

            <div className="parsing-advertising-by-keywords__toolbar-top">
                <FilterContainer
                    onReset={(e) => {
                        setSelectedMp([])
                        setSelectedCategory([]);
                        setSelectedBrand([]);
                        setSelectedKeywords([])
                        setSelectedAddress([])
                        setSelectedProduct([])
                    }}
                >

                    <DropDownSelector
                        options_prop={mpData}
                        state={selectedMp}
                        setState={(e) => setSelectedMp(e)}
                        className="connections_page_selector"
                        placeholder="Площадка"
                        fetchCallback={(e) => setMpPage(e)}
                        setInputChange={(e) => setMpQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={() => {
                            categoryFilter();
                            brandFilter();
                            keywordsFilter()
                            productFilter()
                            addressFilter()
                            deviceFilter()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />


                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={(e) => {
                            setSelectedCategory(e);
                        }}
                        className="connections_page_selector"
                        placeholder="Категория"
                        fetchCallback={(e) => {
                            setCategoryPage(e);
                        }}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                        onBlur={() => {
                            brandFilter();
                            keywordsFilter()
                            productFilter()
                            addressFilter()
                            deviceFilter()
                            fetchTableData()
                            resetChart()
                        }}
                    />


                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={(e) => setSelectedBrand(e)}
                        className="connections_page_selector"
                        placeholder="Бренд"
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            categoryFilter();
                            keywordsFilter()
                            productFilter()
                            addressFilter()
                            deviceFilter()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={keywordsData}
                        state={selectedKeywords}
                        setState={(e) => setSelectedKeywords(e)}
                        // placeholder="Тип рекламы"
                        placeholder="По ключевым словам"
                        fetchCallback={(e) => setKeywordsPage(e)}
                        setInputChange={(e) => setKeywordsQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            categoryFilter();
                            brandFilter();
                            productFilter()
                            addressFilter()
                            deviceFilter()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={productData}
                        state={selectedProduct}
                        setState={(e) => setSelectedProduct(e)}
                        placeholder="Товар"
                        fetchCallback={(e) => setProductPage(e)}
                        setInputChange={(e) => setProductQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            categoryFilter();
                            brandFilter();
                            productFilter()
                            addressFilter()
                            deviceFilter()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={addressData}
                        state={selectedAddress}
                        setState={(e) => setSelectedAddress(e)}
                        placeholder="Адрес"
                        fetchCallback={(e) => setAddressPage(e)}
                        setInputChange={(e) => setAddressQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            categoryFilter();
                            brandFilter();
                            keywordsFilter()
                            productFilter()
                            deviceFilter()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={deviceData}
                        state={selectedDevice}
                        setState={(e) => setSelectedDevice(e)}
                        // placeholder="Тип баннера"
                        placeholder="По устройствам"
                        fetchCallback={(e) => setDevicePage(e)}
                        setInputChange={(e) => setDeviceQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            categoryFilter();
                            brandFilter()
                            keywordsFilter()
                            productFilter()
                            addressFilter()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />
                </FilterContainer>
                <RangeBox
                    defaultValue={defaultDate}
                    setDate={e => setDate(e)} />
            </div>

            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
                name={chartData?.title ? chartData?.title : ''}
            >
                <LineChart
                    selectedMetric={tableMetric}
                    setSelectedMetric={setTableMetric}
                    metricsList={tableMetrics}
                    {...chartData}
                />
            </DropContainer>

            <Tabs tabs={tabs} pageValue={pageValue} />

            <TableBox
                {...tableProps}
                hideSorting={true}
                paginator={true}
                ref={paginatorRef}
                fetchCallback={e => fetchTableData(e)}
                tableUrl={tableUrl}
                tableBody={tableBody}
                onCheck={e => handleCheck(e)}
                onUncheckAllDependence={[
                    pageValue,
                    currentTab,
                    selectedAddress,
                    selectedBrand,
                    selectedCategory,
                    selectedDevice,
                    selectedKeywords,
                    selectedMp
                ]}
                child={
                    <TabsGreen
                        btns={btn_tab}
                        currentBtn={currentTab}
                        setCurrentBtn={(e) => {
                            setCurrentTab(e)
                        }}
                        checkValue={true}
                    />
                }
            />
        </div>
    );
};

export { ParsingAdvertisingByKeywords };
