import Cookies from 'universal-cookie'
import placeholder from './img/noPhoto.png'
import { EditWrapper } from '../EditWrapper/EditWrapper';
import { ButtonBasic, InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { forwardRef, useRef, useState, useImperativeHandle, useEffect } from 'react'
// import { ARTICLE_TYPES } from '../ArticleElement'
import { useDebounce } from '../../../../hooks/useDebounce';
import './ImgArticle.scss'

const ImgArticle = forwardRef(({
    data,
    editMode,
    onChange,
    changeContent,
    position,
    setContentArr,
    id,
    setCurrentIndex,
    ind,
    setIsModal,
    before,
    setBeforeBlock,
    deleteBlock,
    readonly = false
}, ref) => {
    const [extension, setExtension] = useState("")
    const [error, setError] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const fileInputRef = useRef()
    // const showPlaceholder = (data === undefined || data === '') && uploadedImage === undefined
    const cookies = new Cookies()
    const activeLang = localStorage.getItem('lang')
    const [objImg, setObjImg] = useState(null)
    const [altValue, setAltValue] = useState(data[0]?.properties?.alt || '')

    const translatePlaceholderOpen = () => {
        if (activeLang == 'ru') return 'Открыть изображение'
        if (activeLang == 'en') return 'Open image'
        if (activeLang == 'tr') return "Resmi büyüt"
        return 'Открыть изображение'
    }

    const translatePlaceholderSelected = () => {
        if (activeLang == 'ru') return 'Выберите изображение'
        if (activeLang == 'en') return 'Select an image'
        if (activeLang == 'tr') return "Bir resim seçin"
        return 'Выберите изображение'
    }

    useImperativeHandle(ref, () => ({
        error,
        // прикрутить открытие системного диалога с выбором изображения
        focus: () => { },
        data: objImg
    }), [objImg])

    const debounceChange = useDebounce(changeContent, 1000)

    useEffect(() => {
        debounceChange(id,
            [
                objImg
            ]
        )
        setContentArr((prev) => {
            return prev.map((el) => {
                if (el?.block_id == id) {
                    return {
                        ...el,
                        content: [
                            objImg
                        ]
                    }
                } else {
                    return el
                }
            })
        })
    }, [objImg]);

    const fetchPostImg = (file) => {

        const fd = new FormData()
        fd.append('images', file)

        const url = `https://dl.ecomru.ru:6001/clients/images`
        const token = cookies.get('auth')

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad table response')
                }
            })
            .then(json => {
                if (json.images) {
                    setObjImg({ value: json.images?.[0]?.url, properties: { alt: altValue } })
                    // debounceChange({value: json.images?.[0]?.url, type: ARTICLE_TYPES.image, place: position, alt: altValue}, ARTICLE_TYPES.image)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                // mapContentToApiRequest(contentData)
            })
    }

    const handleImageClick = (e) => {
        if (!editMode) {
            const html = document.querySelector('html')
            html.style = 'overflow: hidden'
            setOpen(!isOpen)
            return
        }
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const handleFileInput = (e) => {
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        const fileBase64Reader = new FileReader()
        fileBase64Reader.onload = () => {
            const re = /;base64,/
            const extensionAndBase64 = fileBase64Reader.result?.split(re)
            const onlyExtension = extensionAndBase64[0]?.split('image/')[1]
            setExtension(onlyExtension)
            fetchPostImg(e.target.files[0])
        }
        fileBase64Reader.readAsDataURL(e.target.files[0])
        setError(false)
        onChange()
    }

    useEffect(() => {
        setObjImg({ value: data[0]?.value, properties: { alt: data[0]?.properties?.alt } })
    }, [])

    useEffect(() => {
        if (!isOpen) {
            if (typeof document !== 'undefined') {
                const html = document.querySelector('html')
                html.style = 'overflow: unset'
            }
        }
    }, [isOpen])

    useEffect(() => {
        setObjImg(prev => ({ ...prev, properties: { alt: altValue } }))
    }, [altValue]);

    const handleDeleteBlock = (e) => {
        //  setContent(e.currentTarget.textContent)
        setContentArr((prev) => {
            return prev.filter((el) => {
                if (el?.block_id != id) {
                    return el
                }
            })
        })
        deleteBlock(id)
    }

    useEffect(() => {
        setCurrentIndex(ind)
        setBeforeBlock(before)
    }, []);

    return (
        <EditWrapper
            readonly={readonly}
            deleteFunc={(e) => handleDeleteBlock(id)}
            type='image'
            child={
                <div className='img-container__box'
                    onClick={(e) => {
                        setCurrentIndex(ind)
                        setBeforeBlock(before)
                    }}
                    onContextMenu={(e) => {
                        e.preventDefault()
                        setCurrentIndex(ind)
                        setIsModal(true)
                    }}
                    tabIndex={0}
                >
                    <div className='img-container' onClick={handleImageClick} >
                        <div className='blackout'
                            style={{ display: isOpen ? 'flex' : 'none' }} >
                            <div className='img-box'>
                                <img className='img' src={objImg?.value} />
                            </div>
                        </div>


                        <button className={editMode ? 'img-container__button' : 'img-container__button__readonly'} >
                            {/* {showPlaceholder && <div className='img-container__button__placeholder'></div>} */}
                            <img
                                src={objImg?.value ? objImg?.value : placeholder}
                                onError={e => {
                                    e.target.src = placeholder
                                    setError(true)
                                }}
                                className='img'
                                extension={extension}
                                alt={altValue ? altValue : ''}
                            />
                            <div className='hidden-placeholder'>
                                <p className='text'>{objImg ? translatePlaceholderOpen() : translatePlaceholderSelected()}</p>
                            </div>
                        </button>
                        <input
                            name='uploadedImage'
                            ref={fileInputRef}
                            className='img-container__file-input'
                            type='file'
                            onChange={handleFileInput}
                            accept='image/png, image/jpeg'
                        />


                        {editMode &&
                            <div className='link_modal' onClick={(e) => e.stopPropagation()}>
                                <div className='link_modal_container'>

                                    <InputDinamycPlaceholder
                                        placeholder='Значение alt'
                                        onChange={e => {
                                            setAltValue(e)
                                            debounceChange(id,
                                                [
                                                    objImg
                                                ]
                                            )
                                        }}
                                        changeValue={altValue}
                                        autoComplete={false}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            }
        />
    )
})

export { ImgArticle }