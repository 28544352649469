import React, { forwardRef, useEffect, useState, useRef } from 'react'
import grabZone from './img/bx-grid-vertical.svg';
import "./EditWrapper.scss"

const EditWrapper = (({
    type = 'text',
    child,
    deleteFunc = () => { },
    readonly = false
}) => {

    // Возвращаем класс в зависимости от типа блока
    const current_className = () => {
        if (type) return `edit-article-wrapper__${type}`
        return ''
    }

    return (
        <div className={`edit-article-wrapper ${current_className()}`}>
            {!readonly && <img className='edit-article-wrapper__drag' src={grabZone} />}

            {child}

            {!readonly && <div class="edit-article-wrapper__close"
                // удаление блока
                onClick={(e) => deleteFunc()}
            ></div>}
        </div>
    )
})

export { EditWrapper }